import React, { Component } from 'react'
import './header.scss'
import logo from '../../assets/img/logo.png'
import x from '../../assets/img/x.png'
import more from '../../assets/img/more.png'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import Index from '../../pages/index'
import Work from '../../pages/work'
import Relation from '../../pages/relation'
import Innovation from '../../pages/innovation'
import Recruit from '../../pages/recruit'
import WorkNotion from '../../pages/workNotion'
import Scholarship from '../../pages/scholarship'
import Competition from '../../pages/competition'
import Zju from '../../pages/scholarship_zju'
import Culture from '../../pages/culture'
import Mission from '../../pages/mission'
import Tea from '../../pages/afternoon_tea'
import Environment from '../../pages/environment'
import Seminar from '../../pages/seminar'
import Sports from '../../pages/sports'
import Product from '../../pages/product'
import Contact from '../../pages/contact'
import store from '../../store/index';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acBar: 0,
            navList: [
                { title: '首页', url: '/' },
                { title: '工作与生活', url: '/work' },
                // { title: '投资者关系', url: '/relation' },
                { title: '支持的创新', url: '/innovation' },
                { title: '加入我们', url: '/recruit' },
            ],
            mobile: false,
            slideIn: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize.bind(this))
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
        let url = store.getState().index
        if (url === '/') {
            this.setState({
                acBar: 0
            })
        } else if (url === '/work') {
            this.setState({
                acBar: 1
            })
        } else if (url === '/relation' || url === '/product' || url === '/relation/' || url === '/product/') {
            this.setState({
                acBar: 2
            })
        } else if (url === '/innovation') {
            this.setState({
                acBar: 3
                // acBar: 2
            })
        } else if (url === '/recruit') {
            this.setState({
                acBar: 4
                // acBar: 3
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        let url = store.getState().index
        if (prevState.acBar === this.state.acBar) {
            return
        } else {
            if (url === '/') {
                this.setState({
                    acBar: 0
                })
            } else if (url === '/work') {
                this.setState({
                    acBar: 1
                })
            } else if (url === '/relation' || url === '/product') {
                this.setState({
                    acBar: 2
                })
            } else if (url === '/innovation') {
                this.setState({
                    // acBar: 3
                    acBar: 2
                })
            } else if (url === '/recruit') {
                this.setState({
                    // acBar: 4
                    acBar: 3
                })
            }
        }
    }

    onResize(e) {
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
    }

    chooseNav(index) {
        console.log(index)
        this.setState({
            acBar: index,
            mobile_nav: false,
        })
    }
    showNav = () => {
        this.setState({
            mobile_nav: true,
            slideIn: true
        })
    }
    closeNav = () => {
        this.setState({
            mobile_nav: false,
            slideIn: false
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    render() {
        const { navList, acBar, mobile, slideIn } = this.state
        return (
            <Router>
                <div className={'head_wrapper ' + (acBar === 2 ? 'true' : '')} >
                    <Link to='/' className="left">
                        <img alt='logo' src={logo} />
                    </Link>
                    <div className="right">
                        {mobile ?
                            <img src={more} alt='img' className='more' onClick={this.showNav.bind(this)} /> :
                            <>
                                {navList.map((item, index) => {
                                    return (
                                        <Link to={item.url} className={acBar === index ? 'active' : ''} onClick={this.chooseNav.bind(this, index)} key={index + 1} > {item.title}</Link>
                                    )
                                })}
                            </>
                        }
                    </div>
                    <div className={"mobile_nav " + (slideIn ? 'true' : 'false')} onClick={this.closeNav.bind(this)} >
                        <div className='head'>
                            <div className="close">
                                <img alt='logo' src={x} />
                            </div>
                        </div>
                        <div className="link_wrapper">
                            {navList.map((item, index) => {
                                return (
                                    <Link to={item.url} className={acBar === index ? 'active' : ''} onClick={this.chooseNav.bind(this, index)} key={index + 1} > {item.title}</Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <Route path='/' exact component={Index} />
                <Route path='/work/' component={Work} />
                {/* <Route path='/relation/' component={Relation} /> */}
                <Route path='/innovation/' component={Innovation} />
                <Route path='/recruit/' component={Recruit} />
                <Route path='/workNotion/' component={WorkNotion} />
                <Route path='/scholarship/' component={Scholarship} />
                <Route path='/competition/' component={Competition} />
                <Route path='/zju/' component={Zju} />
                <Route path='/culture/' component={Culture} />
                <Route path='/mission/' component={Mission} />
                <Route path='/tea/' component={Tea} />
                <Route path='/environment/' component={Environment} />
                <Route path='/seminar/' component={Seminar} />
                <Route path='/sports/' component={Sports} />
                <Route path='/product/' component={Product} />
                <Route path='/contact/' component={Contact} />
            </Router>
        );
    }
}

export default Header;