import React, { Component } from 'react'
import axios from 'axios';
import Highcharts from 'highcharts/highstock'
import './product.scss'
import Chart from '../component/Chart.jsx'
import Footer from '../component/Footer/footer'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'

require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts/modules/map')(Highcharts)

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options1: {},
            options2: {},
            options3: {},
            options4: {},
            nav: [
                { title: '单利宝1号' },
                { title: '旅行者1号' },
                { title: '旅行者1000指增' },
                { title: '水手2号' },
                { title: '水手3号' },
                { title: '破晓1号' },
            ],
            // navTop: [
            //     { title: '高频日内T0策略' },
            //     { title: '日频Alpha选股策略' },
            // ],
            acTab: 0,
            acTopTab: 0,
            chartList: {},
            stockData: {},
            stockData1: {},
            stockData2: {},
            stockData3: {},
            stockData4: {},
            stockData5: {},
            stockData6: {},
            mobile: false,
        }
    }

    componentDidMount() {
        const cur = this
        store.dispatch(changeIndex(this.props.location.pathname))
        if (sessionStorage.getItem('higgsLogin') == 'true') {
            axios.get('https://www.higgsasset.com/higgs/web/api/getSummaryData').
                then(res => {
                    let data = res.data.data
                    cur.setState({
                        chartList: data
                    })
                    cur.getChart(data, 0)
                })
        } else {
            this.props.history.push('/relation/')
        }
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
    }

    getChart(data, index) {
        let topTab = this.state.acTopTab
        let list = []
        if (index === 0) {
            list = data.dlb1
        } else if (index === 1) {
            list = data.lvxz1
        } else if (index === 2) {
            list = data.lvxz1000
        } else if (index === 3) {
            list = data.sailor2
        } else if (index === 4) {
            list = data.sailor3
        } else if (index === 5) {
            list = data.poxiao1
        }

        let arr = []
        let arr1 = []
        let arr2 = []
        let arr3 = []
        for (let i = 0; i < list.length; i++) {
            if (index === 1) {
                if (list[i].zz500 && list[i].cum_nav_value) {
                    arr.push(this.formatnumber(list[i].cum_nav_value))
                    let b = Number(list[i].zz500).toFixed(4)
                    let c = this.formatnumber(list[i].nav_value)
                    arr2.push(Number(b))
                    arr3.push(c)
                }
            } else if (index == 0) {
                arr.push(this.formatnumber(list[i].cum_nav_value))
                let b = this.formatnumber(list[i].nav_value)
                arr2.push(b)
                // arr.push(this.formatnumber(list[i].nav_value))
            } else if (index === 2) {
                if (list[i].zz1000 && list[i].cum_nav_value) {
                    arr.push(this.formatnumber(list[i].cum_nav_value))
                    let b = Number(list[i].zz1000).toFixed(4)
                    let c = this.formatnumber(list[i].nav_value)
                    arr2.push(Number(b))
                    arr3.push(c)
                }
            } else if (index === 3) {
                if (list[i].zz500 && list[i].cum_nav_value) {
                    arr.push(this.formatnumber(list[i].cum_nav_value))
                    let b = Number(list[i].zz500).toFixed(4)
                    let c = this.formatnumber(list[i].nav_value)
                    arr2.push(Number(b))
                    arr3.push(c)
                }
            } else if (index === 4) {
                arr.push(this.formatnumber(list[i].cum_nav_value))
                let b = this.formatnumber(list[i].nav_value)
                arr2.push(b)
            } else if (index === 5) {
                if (list[i].zz500 && list[i].cum_nav_value) {
                    arr.push(this.formatnumber(list[i].cum_nav_value))
                    let b = Number(list[i].zz500).toFixed(4)
                    let c = this.formatnumber(list[i].nav_value)
                    arr2.push(Number(b))
                    arr3.push(c)
                }
            }
            else {
                arr.push(this.formatnumber(list[i].nav_value))
            }
            arr1.push(list[i].date)
        }

        if (index === 1) {
            this.setState({
                options4: {
                    lang: {
                        contextButtonTitle: "图表导出菜单",
                        decimalPoint: ".",
                        downloadCSV: '下载 CSV文件',
                        downloadJPEG: "下载JPEG图片",
                        downloadPDF: "下载PDF文件",
                        downloadPNG: "下载PNG文件",
                        downloadSVG: "下载SVG文件",
                        drillUpText: "返回 {series.name}",
                        loading: "加载中",
                        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
                        noData: "没有数据",
                        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
                        printChart: "打印图表",
                        resetZoom: "恢复缩放",
                        resetZoomTitle: "恢复图表",
                        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        thousandsSep: ",",
                        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"]
                    },
                    title: {
                        text: '旅行者1号'
                    },
                    yAxis: [{
                        title: {
                            text: '净值'
                        }
                    }],
                    // legend: {
                    //     labelFormat: '每日净值'
                    // },
                    credits: {
                        enabled: false
                    },
                    chart: {
                        zoomType: 'x'
                    },
                    xAxis: {
                        categories: arr1
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 4,
                    },
                    series: [
                        {
                            name: '累计净值',
                            data: arr,
                        },
                        {
                            type: 'spline',
                            name: '中证500指数',
                            color: '#000',
                            data: arr2,
                        },
                        {
                            name: '单位净值',
                            color: '#fff0',
                            data: arr3,
                            showInLegend: false
                        },
                    ]
                }
            })
        } else if (index === 0) {
            this.setState({
                options: {
                    lang: {
                        contextButtonTitle: "图表导出菜单",
                        decimalPoint: ".",
                        downloadCSV: '下载 CSV文件',
                        downloadJPEG: "下载JPEG图片",
                        downloadPDF: "下载PDF文件",
                        downloadPNG: "下载PNG文件",
                        downloadSVG: "下载SVG文件",
                        drillUpText: "返回 {series.name}",
                        loading: "加载中",
                        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
                        noData: "没有数据",
                        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
                        printChart: "打印图表",
                        resetZoom: "恢复缩放",
                        resetZoomTitle: "恢复图表",
                        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        thousandsSep: ",",
                        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"]
                    },
                    title: {
                        text: index === 0 ? '单利宝1号' : (index === 1 ? '新视野1号' : (index === 2 ? '新视野3号' : '旅行者1号'))
                    },
                    yAxis: [{
                        title: {
                            text: '净值'
                        }
                    }],
                    // legend: {
                    //     labelFormat: '每日净值'
                    // },
                    credits: {
                        enabled: false
                    },
                    chart: {
                        zoomType: 'x'
                    },
                    xAxis: {
                        categories: arr1
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 4,
                    },
                    series: [
                        {
                            name: '累计净值',
                            data: arr,
                        },
                        {
                            // type: 'spline',
                            name: '单位净值',
                            color: '#fff0',
                            data: arr2,
                            showInLegend: false
                        }

                    ]
                }
            })
        } else if (index === 2) {
            this.setState({
                options1: {
                    lang: {
                        contextButtonTitle: "图表导出菜单",
                        decimalPoint: ".",
                        downloadCSV: '下载 CSV文件',
                        downloadJPEG: "下载JPEG图片",
                        downloadPDF: "下载PDF文件",
                        downloadPNG: "下载PNG文件",
                        downloadSVG: "下载SVG文件",
                        drillUpText: "返回 {series.name}",
                        loading: "加载中",
                        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
                        noData: "没有数据",
                        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
                        printChart: "打印图表",
                        resetZoom: "恢复缩放",
                        resetZoomTitle: "恢复图表",
                        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        thousandsSep: ",",
                        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"]
                    },
                    title: {
                        text: '旅行者中证1000指增'
                    },
                    yAxis: [{
                        title: {
                            text: '净值'
                        }
                    }],
                    credits: {
                        enabled: false
                    },
                    chart: {
                        zoomType: 'x'
                    },
                    xAxis: {
                        categories: arr1
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 4,
                    },
                    series: [
                        {
                            name: '累计净值',
                            data: arr,
                        },
                        {
                            type: 'spline',
                            name: '中证1000指数',
                            color: '#000',
                            data: arr2,
                        },
                        {
                            name: '单位净值',
                            color: '#fff0',
                            data: arr3,
                            showInLegend: false
                        },
                    ]
                }
            })
        } else if (index === 3) {
            this.setState({
                options1: {
                    lang: {
                        contextButtonTitle: "图表导出菜单",
                        decimalPoint: ".",
                        downloadCSV: '下载 CSV文件',
                        downloadJPEG: "下载JPEG图片",
                        downloadPDF: "下载PDF文件",
                        downloadPNG: "下载PNG文件",
                        downloadSVG: "下载SVG文件",
                        drillUpText: "返回 {series.name}",
                        loading: "加载中",
                        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
                        noData: "没有数据",
                        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
                        printChart: "打印图表",
                        resetZoom: "恢复缩放",
                        resetZoomTitle: "恢复图表",
                        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        thousandsSep: ",",
                        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"]
                    },
                    title: {
                        text: '水手2号'
                    },
                    yAxis: [{
                        title: {
                            text: '净值'
                        }
                    }],
                    // legend: {
                    //     labelFormat: '每日净值'
                    // },
                    credits: {
                        enabled: false
                    },
                    chart: {
                        zoomType: 'x'
                    },
                    xAxis: {
                        categories: arr1
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 4,
                    },
                    series: [
                        {
                            name: '累计净值',
                            data: arr,
                        },
                        {
                            type: 'spline',
                            name: '中证500指数',
                            color: '#000',
                            data: arr2,
                        },
                        {
                            name: '单位净值',
                            color: '#fff0',
                            data: arr3,
                            showInLegend: false
                        },
                    ]
                }
            })
        } else if (index === 4) {
            this.setState({
                options1: {
                    lang: {
                        contextButtonTitle: "图表导出菜单",
                        decimalPoint: ".",
                        downloadCSV: '下载 CSV文件',
                        downloadJPEG: "下载JPEG图片",
                        downloadPDF: "下载PDF文件",
                        downloadPNG: "下载PNG文件",
                        downloadSVG: "下载SVG文件",
                        drillUpText: "返回 {series.name}",
                        loading: "加载中",
                        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
                        noData: "没有数据",
                        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
                        printChart: "打印图表",
                        resetZoom: "恢复缩放",
                        resetZoomTitle: "恢复图表",
                        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        thousandsSep: ",",
                        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"]
                    },
                    title: {
                        text: '水手3号'
                    },
                    yAxis: [{
                        title: {
                            text: '净值'
                        }
                    }],
                    // legend: {
                    //     labelFormat: '每日净值'
                    // },
                    credits: {
                        enabled: false
                    },
                    chart: {
                        zoomType: 'x'
                    },
                    xAxis: {
                        categories: arr1
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 4,
                    },
                    series: [
                        {
                            name: '累计净值',
                            data: arr,
                        },
                        // {
                        //     type: 'spline',
                        //     name: '中证500指数',
                        //     color: '#000',
                        //     data: arr2,
                        // },
                        {
                            name: '单位净值',
                            color: '#fff0',
                            data: arr2,
                            showInLegend: false
                        },
                    ]
                }
            })
        } else if (index === 5) {
            this.setState({
                options1: {
                    lang: {
                        contextButtonTitle: "图表导出菜单",
                        decimalPoint: ".",
                        downloadCSV: '下载 CSV文件',
                        downloadJPEG: "下载JPEG图片",
                        downloadPDF: "下载PDF文件",
                        downloadPNG: "下载PNG文件",
                        downloadSVG: "下载SVG文件",
                        drillUpText: "返回 {series.name}",
                        loading: "加载中",
                        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
                        noData: "没有数据",
                        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
                        printChart: "打印图表",
                        resetZoom: "恢复缩放",
                        resetZoomTitle: "恢复图表",
                        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        thousandsSep: ",",
                        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"]
                    },
                    title: {
                        text: '破晓1号'
                    },
                    yAxis: [{
                        title: {
                            text: '净值'
                        }
                    }],
                    // legend: {
                    //     labelFormat: '每日净值'
                    // },
                    credits: {
                        enabled: false
                    },
                    chart: {
                        zoomType: 'x'
                    },
                    xAxis: {
                        categories: arr1
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 4,
                    },
                    series: [
                        {
                            name: '累计净值',
                            data: arr,
                        },
                        // {
                        //     type: 'spline',
                        //     name: '中证500指数',
                        //     color: '#000',
                        //     data: arr2,
                        // },
                        {
                            name: '单位净值',
                            color: '#fff0',
                            data: arr3,
                            showInLegend: false
                        },
                    ]
                }
            })
        }

        this.setState({
            stockData: list[list.length - 1]
        })

    }

    chooseNav(index) {
        let topTab = this.state.acTopTab
        if (index === 0) {
            this.getChart(this.state.chartList, 0)
        } else if (index === 1) {
            this.getChart(this.state.chartList, 1)
        } else if (index === 2) {
            this.getChart(this.state.chartList, 2)
        } else if (index === 3) {
            this.getChart(this.state.chartList, 3)
        } else if (index === 4) {
            this.getChart(this.state.chartList, 4)
        } else if (index === 5) {
            this.getChart(this.state.chartList, 5)
        }


        this.setState({
            acTab: index
        })
    }

    chooseNavTop(index) {
        const cur = this
        if (index === 0) {
            let arr = [
                { title: '单利宝1号' },
                { title: '旅行者1号(500指增)' },
                { title: '旅行者中证1000指增' }
            ]
            cur.setState({
                nav: arr,
                acTopTab: 0,
                acTab: 0
            }, () => {
                cur.chooseNav(0)
            })
        } else if (index === 1) {
            let arr = [
                { title: '水手2号' },
                { title: '水手3号' },
                { title: '破晓1号' },
            ]
            cur.setState({
                nav: arr,
                acTopTab: 1,
                acTab: 0
            }, () => {
                cur.chooseNav(0)
            })
        }
    }

    componentWillUnmount() {
        window.scrollTo(0, 0)
    }

    formatnumber(value) {
        let b = Number(value).toFixed(4)
        return Number(b)
    }

    render() {
        const { options, options1, options2, options3, options4, nav, acTab, stockData, navTop, acTopTab, mobile } = this.state;
        return (
            <div>
                <img alt='img1' className='product_Img' src='https://www.higgsasset.com/img/web/39.jpg' />
                <div className="product_content">

                    {/* <div className='nav'>
                        {navTop.map((item, index) => {
                            return (
                                <div className={"item " + (acTopTab === index ? 'active' : '')}
                                    onClick={this.chooseNavTop.bind(this, index)} key={index + 1} >
                                    <div className='item_wrap'>
                                        {item.title}
                                        <div className={'line ' + (acTopTab === index ? 'active' : '')}></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}

                    <div className='nav sec '>
                        {nav.map((item, index) => {
                            return (
                                <div className={"item " + (acTab === index ? 'active' : '')  + (mobile ? ' mobile' : '')} onClick={this.chooseNav.bind(this, index)} key={index + 1} >
                                    {item.title}
                                    <div className={'line ' + (acTab === index ? 'active' : '')}></div>
                                </div>
                            )
                        })}
                    </div>

                    {acTab === 0 && acTopTab === 0 && <div className='product_detail'>
                        <div className="title">希格斯单利宝1号</div>
                        <div className="titles">净值表现</div>
                        <div className="text"> 截止到{stockData.date} , 希格斯单利宝1号最新累计净值为 <span>{stockData.cum_nav_value}</span>。更多产品信息，请参见产品要素表。</div>
                        <div className="chart_wrap">
                            <div className='title'>每日累计净值走势图</div>
                            <Chart options={options} highcharts={Highcharts} />
                        </div>
                    </div>}
                    {acTab === 1 && acTopTab === 0 && <div className='product_detail'>
                        <div className="title">希格斯旅行者1号</div>
                        <div className="titles">净值表现</div>
                        <div className="text">截止到{stockData.date}, 希格斯旅行者1号最新累计净值为<span>{stockData.cum_nav_value}</span>。更多产品信息，请参见产品要素表。</div>
                        <div className="chart_wrap">
                            <div className='title'>每日累计净值走势图</div>
                            <Chart options={options4} highcharts={Highcharts} />
                        </div>
                    </div>}
                    {acTab === 2 && acTopTab === 0 && <div className='product_detail'>
                        <div className="title">希格斯旅行者中证1000指数增强</div>
                        <div className="titles">净值表现</div>
                        <div className="text">截止到{stockData.date}, 希格斯旅行者中证1000最新累计净值为<span>{stockData.cum_nav_value}</span>。更多产品信息，请参见产品要素表。</div>
                        <div className="chart_wrap">
                            <div className='title'>每日累计净值走势图</div>
                            <Chart options={options1} highcharts={Highcharts} />
                        </div>
                    </div>}

                    {acTab === 3 && acTopTab === 0 && <div className='product_detail'>
                        <div className="title">希格斯水手2号</div>
                        <div className="titles">净值表现</div>
                        <div className="text">截止到{stockData.date}, 希格斯水手2号最新累计净值为<span>{stockData.cum_nav_value}</span>。更多产品信息，请参见产品要素表。</div>
                        <div className="chart_wrap">
                            <div className='title'>每日累计净值走势图</div>
                            <Chart options={options1} highcharts={Highcharts} />
                        </div>
                    </div>}
                    {acTab === 4 && acTopTab === 0 && <div className='product_detail'>
                        <div className="title">希格斯水手3号</div>
                        <div className="titles">净值表现</div>
                        <div className="text">截止到{stockData.date}, 希格斯水手3号最新累计净值为<span>{stockData.cum_nav_value}</span>。更多产品信息，请参见产品要素表。</div>
                        <div className="chart_wrap">
                            <div className='title'>每日累计净值走势图</div>
                            <Chart options={options1} highcharts={Highcharts} />
                        </div>
                    </div>}
                    {acTab === 5 && acTopTab === 0 && <div className='product_detail'>
                        <div className="title">希格斯破晓1号</div>
                        <div className="titles">净值表现</div>
                        <div className="text">截止到{stockData.date}, 希格斯破晓1号最新累计净值为<span>{stockData.cum_nav_value}</span>。更多产品信息，请参见产品要素表。</div>
                        <div className="chart_wrap">
                            <div className='title'>每日累计净值走势图</div>
                            <Chart options={options1} highcharts={Highcharts} />
                        </div>
                    </div>}
                    {acTab === 0 && acTopTab === 0 && <>
                        <div className="title">产品类型</div>
                        <p>中性对冲产品</p>
                        <div className="title">业绩比较基准</div>
                        <p>不设业绩比较基准</p>
                        <div className="title">投资策略</div>
                        <p>本产品的投资策略为市场中性策略，本产收益主要来自于对股票市场微观结构的分析研究。策略捕捉股票市场较短期的定价无效，并通过交易纠正定价偏差以获得相应的收益。</p>
                        <div className="title">申购及追加认购起点</div>
                        <p>100万元，10万元</p>
                        <div className="title">开放日</div>
                        <p>每个月第2个工作日</p>
                        <div className="title">封闭期</div>
                        <p>无</p>
                        <div className="title">赎回费</div>
                        <p>向持有产品不满【365】天的份额收取，费率为0.50%</p>
                        <div className="title">预警与止损</div>
                        <p>预警线0.92，止损线0.90</p>
                        <div className="title">管理费率</div>
                        <p>1%</p>
                        <div className="title">业绩报酬计提方式</div>
                        <p>基金投资者赎回、分红、清算时计提业绩报酬（无业绩比较基准），提取比例20%</p>
                        <div className="title">收益分配</div>
                        <p>默认现金分红，每年收益最多分配4次</p>
                    </>}
                    {acTab === 1 && acTopTab === 0 &&
                        <>
                            <div className="title">产品类型</div>
                            <p>指数增强型产品</p>
                            <div className="title">业绩比较基准</div>
                            <p>中证500指数（000905）</p>
                            <div className="title">投资策略</div>
                            <p>本产品的投资策略为指数增强策略，底仓跟踪中证500指数成分股。即在被动跟踪中证500指数的基础上，利用股票日内策略或者Alpha策略赚取超额回报。</p>
                            <div className="title">申购及追加认购起点</div>
                            <p>100万元，10万元</p>
                            <div className="title">开放日</div>
                            <p>每个自然年奇数月（1、3、5、7、9、11月）的第20日</p>
                            <div className="title">封闭期</div>
                            <p>无</p>
                            <div className="title">赎回费</div>
                            <p>赎回费向持有产品不满180天的客户收取，费率为0.5%；向持有产品满180天小于365天的客户收取，费率为0.2%</p>
                            <div className="title">预警与止损</div>
                            <p>预警线无，止损线0.70</p>
                            <div className="title">管理费率</div>
                            <p>1%</p>
                            <div className="title">业绩报酬计提方式</div>
                            <p>在收益分配基准日、赎回日及基金终止日，若持有期年化收益率小于等于中证500指数年化增长率（000905），则不计提业绩报酬； 若持有期年化收益率大于中证500指数年化增长率（000905），则管理人对超出部分按20%的比例提取业绩报酬</p>
                            <div className="title">收益分配</div>
                            <p>现金分红，每年收益最多分配6次</p>
                        </>
                    }
                    {acTab === 2 && acTopTab === 0 &&
                        <>
                            <div className="title">产品类型</div>
                            <p>指数增强型产品</p>
                            <div className="title">业绩比较基准</div>
                            <p>中证1000指数（000852）</p>
                            <div className="title">投资策略</div>
                            <p>投资策略为指数增强策略，底仓跟踪中证1000指数，力求利用股票日内策略或者Alpha策略赚取超额回报 。管理人有权根据基金运作情况自行调整投资策略。</p>
                            <div className="title">申购及追加认购起点</div>
                            <p>100万元，10万元</p>
                            <div className="title">开放日</div>
                            <p>每个自然月的第10日</p>
                            <div className="title">封闭期</div>
                            <p>无</p>
                            <div className="title">赎回费</div>
                            <p>赎回费向持有产品不满360天的客户收取，费率为0.5%</p>
                            <div className="title">预警与止损</div>
                            <p>无预警止损线</p>
                            <div className="title">管理费率</div>
                            <p>1%</p>
                            <div className="title">业绩报酬计提方式</div>
                            <p>1. 业绩报酬的计提时间：投资者赎回、分红、基金清算</p>
                            <p>2. 业绩报酬的计算：“单客户单笔份额高水位法”，提取比例20%</p>
                            <div className="title">收益分配</div>
                            <p>现金分红或红利再投，默认现金分红；每年收益最多分配4次</p>
                        </>
                    }
                    {acTab === 3 && acTopTab === 0 &&
                        <>
                            <div className="title">产品类型</div>
                            <p>指数增强型产品</p>
                            <div className="title">业绩比较基准</div>
                            <p>中证500指数（000905）</p>
                            <div className="title">投资策略</div>
                            <p>本产品为指数增强策略，专注于获得超出市场平均水平的alpha收益。本产品的收益主要来自于对预期收益超出大盘表现股票的挑选，和对市场微观结构的分析研究。</p>
                            <div className="title">申购及追加认购起点</div>
                            <p>100万元，100万元</p>
                            <div className="title">开放日</div>
                            <p>每个自然月20日</p>
                            <div className="title">封闭期</div>
                            <p>无</p>
                            <div className="title">赎回费</div>
                            <p>向持有产品不满180天（不含）的份额收取，费率为0.5%，向持有产品满180天（含）不满365天（不含）的客户收取，费率为0.2%</p>
                            <div className="title">预警与止损</div>
                            <p>预警线无，止损线0.70</p>
                            <div className="title">管理费率</div>
                            <p>1%</p>
                            <div className="title">业绩报酬计提方式</div>
                            <p>1. 业绩报酬的计提时间：投资者赎回、分红、基金清算</p>
                            <p>2. 业绩报酬的计算：“单客户单笔份额高水位法”，提取比例20%</p>
                            <div className="title">收益分配</div>
                            <p>现金红利或红利转份额，默认现金分红；每年收益最多分配4次</p>
                        </>
                    }
                    {acTab === 4 && acTopTab === 0 &&
                        <>
                            <div className="title">产品类型</div>
                            <p>中性对冲产品</p>
                            <div className="title">投资策略</div>
                            <p>本产品的投资策略为alpha策略，在对冲beta风险的前提下，专注于获得超出市场平均水平的alpha收益。本产品的alpha收益主要来自于对预期收益超出大盘表现股票的挑选，和对市场微观结构的分析研究。</p>
                            <div className="title">申购及追加认购起点</div>
                            <p>100万元，100万元</p>
                            <div className="title">开放日</div>
                            <p>每个自然月15日和25日</p>
                            <div className="title">封闭期</div>
                            <p>无</p>
                            <div className="title">赎回费</div>
                            <p>向持有产品不满365天（不含）的客户收取，费率为0.5%</p>
                            <div className="title">预警与止损</div>
                            <p>预警线0.90，止损线0.85</p>
                            <div className="title">管理费率</div>
                            <p>1%</p>
                            <div className="title">业绩报酬计提方式</div>
                            <p>1. 业绩报酬的计提时间：投资者赎回、分红、基金清算</p>
                            <p>2. 业绩报酬的计算：“单客户单笔份额高水位法”，提取比例20%</p>
                            <div className="title">收益分配</div>
                            <p>现金分红或红利转份额；每年收益最多分配4次</p>
                        </>
                    }

                    {acTab === 5 && acTopTab === 0 &&
                        <>
                            <div className="title">产品类型</div>
                            <p>量化多头产品 <strong>【 持仓风格不对标任何指数】</strong> </p>
                            <div className="title">业绩比较基准</div>
                            <p>无</p>
                            <div className="title">投资策略</div>
                            <p>本基金主要采取alpha选股策略，专注于获得超出市场平均水平的alpha收益。本产品的alpha收益主要来自于对预期收益超出大盘表现股票的挑选，和对市场微观结构的分析研究。本基金可择时投资于中国存托凭证。管理人可根据具体运作情况调整投资策略。</p>
                            <div className="title">申购及追加认购起点</div>
                            <p>100万元，10万元</p>
                            <div className="title">开放日</div>
                            <p>每周四为固定申购开放日，每个自然月最后一个周四为固定赎回开放日</p>
                            <div className="title">封闭期</div>
                            <p>无</p>
                            <div className="title">赎回费</div>
                            <p>赎回费向持有产品不满365天的份额收取，费率为1.00%</p>
                            <div className="title">预警与止损</div>
                            <p>预警线0.6，止损线0.50</p>
                            <div className="title">管理费率</div>
                            <p>1%</p>
                            <div className="title">业绩报酬计提方式</div>
                            <p>1. 业绩报酬的计提时间：投资者赎回、分红、基金清算</p>
                            <p>2. 业绩报酬的计算：“单客户单笔份额高水位法”，提取比例20%</p>
                            <div className="title">收益分配</div>
                            <p>现金分红或红利转份额，默认为红利转份额。由管理人决定是否进行收益分配，每年的收益分配不超过1次</p>
                        </>
                    }
                </div>
                <Footer></Footer>
            </div >
        );
    }
}

export default Product;