import React, { useState } from 'react'

import './recruit_title.scss'
import ArrowLeft from '../../../assets/img/arrow_left.png'
import ArrowRight from '../../../assets/img/arrow_right.png'


export default ({ title, type }) => {


    return (
        <div className='recruit_title'>
            {type == 1 ?
                <> <div className="title">
                    {/* <img alt='img1' className='arrow_img' src={ArrowLeft} /> */}
                    <span>{title}</span>
                    {/* <img alt='img1' className='arrow_img' src={ArrowRight} /> */}
                </div>
                </> :
                <>
                    <div className="title">
                        <span>{title}</span>
                    </div>
                </>
            }
        </div>
    )

}
