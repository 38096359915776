import React, { Component } from 'react'
import './contact.scss'
import contact_wx from '../assets/img/contact-wx.jpg'
import Footer from '../component/Footer/footer'
import ReactQMap from 'react-qmap';
import QQMap from 'qqmap';
class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
    }
    //地图初始化的方法
    initQQMap = () => {
        QQMap.init('GMCBZ-ZFXRD-WKD4J-PQETH-IPZZS-XKFTA', () => {
        });
    }

    renderMap = () => {
    }

    render() {
        return (
            <div>
                <ReactQMap
                    center={{ latitude: 30.181944, longitude: 120.201063 }}
                    mySpot={{ latitude: 30.181944, longitude: 120.201063 }}
                    initialOptions={{ zoomControl: true, mapTypeControl: true }}
                    apiKey="GMCBZ-ZFXRD-WKD4J-PQETH-IPZZS-XKFTA"
                    style={{ height: 600 }}    
                />
                
                <div className="contact_content">
                    <div className="title">联系我们</div>
                    <div className="wrap">
                        <div className="left">
                            <p className='blank'>杭州希格斯投资管理有限公司</p>
                            <p className='blank'>浙江省杭州市滨江区滨康路308号</p>
                            <p>+86-571-8724-8912</p>
                        </div>
                        <div className="right">
                            <img alt='img1' src={contact_wx} />
                        </div>
                    </div>
                </div>
                <Footer type={2}></Footer>
            </div>
        )
    }
}

export default Contact