import React, { Component } from 'react'
import Footer from '../component/Footer/footer'
import './competition.scss'

class Competition extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <img alt='img1' className='mission_Img' src={'https://www.higgsasset.com/img/web/68.jpg'} />
                <div className="competition_content">
                    <div className='title'>“希格斯杯”第二届中国研究生人工智能创新大赛浙江大学校内选拔赛</div>
                    <div className="content">
                        <p>“中国研究生人工智能创新大赛”（以下简称“大赛”，英文名称：China Graduate AI Innovation Competition）是“中国研究生创新实践系列大赛”主题赛事之一，由教育部学位与研究生教育发展中心指导、中国科协青少年科技中心主办。大赛秘书处设于浙江大学，本届中国研究生人工智能创新大赛由国防科技大学承办。</p>
                        <p>大赛以“AI赋能、创新引领”为理念，围绕新一代人工智能创新主题，引领未来的战略性技术，激发研究生创新意识，提高研究生创新和实践能力，着力培养创新型、复合型、应用型高端人才，为人工智能领域健康发展提供人才支撑。大赛的目标是联合多方力量，努力把大赛办成在研究生群体、研究生培养单位和社会中有较大影响力，被国内外研究生培养单位和企业行业广泛认可的高层次、全国性重要赛事，逐步实现与同类型高水平国际赛事接轨。</p>
                        <p>“希格斯杯”第二届中国研究生人工智能创新大赛浙江大学校内选拔赛由杭州希格斯投资管理有限公司赞助举办。</p>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Competition;