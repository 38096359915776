import React, { Component } from 'react'
import Footer from '../component/Footer/footer'
import './culture.scss'
import img195 from '../assets/img/195.jpg'
import img196 from '../assets/img/196.jpg'

class Culture extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <div className="culture_content">
                    <img alt='img1' className='textImg' src={'https://www.higgsasset.com/img/web/48.jpg'} />
                    <div className="head">
                        <div className="title b">希格斯文化</div>
                        <div className="text">在希格斯投资，你会反复听到的四个词： </div>
                        <div className="text b">正直、开放、求真、求精</div>
                        <div className="text">这四个词构成了希格斯投资的行为准则，也支撑起了希格斯投资的文化。</div>
                    </div>
                    <div className='img_wrapper'>
                        <img alt='img1' className='textImg' src={'https://www.higgsasset.com/img/web/49.jpg'} />
                        <div className="t">正直</div>
                    </div>
                    <div className="desc">
                        <div className="dtl">
                            <div className="title b">我们追求正直的事业</div>
                            <p>希格斯投资作为一家投资公司，主要通过交易获得利润。在此过程之中，我们确保： </p>
                            <ul>
                                <li>我们在交易中所建立的所有优势均来自于研究和开发；</li>
                                <li>不通过操纵市场获利；</li>
                                <li>不通过内幕交易获利。</li>
                            </ul>
                            <p>我们期待通过努力工作，给有关投资的科学问题找到最终的解答，并让价格能够反映价值，从而创建一个更好的社会。</p>
                        </div>
                        <div className="dtl">
                            <div className="title b" >正直的对待所有利益相关者</div>
                            <p>希格斯的文化要求我们正直的对待所有利益相关者，包括客户，员工，与我们合作的机构或个人，以及市场中的其他交易者。 </p>
                            <p className='nmt'>对于那些通过不正当手段损害相关者利益的事情，我们：</p>
                            <ul>
                                <li>不去设想；</li>
                                <li>不去讨论；</li>
                                <li>如果遇到有人去设想和讨论，则自觉批评，制止和抵制之。</li>
                            </ul>
                            <p>孟子说的人身三乐之中有一乐是“仰不愧于天，俯不怍于人”。正直的对待所有利益相关者，本身就是人生至乐。</p>
                        </div>
                        <div className="dtl">
                            <div className="title b">正直的受益者是我们自身</div>
                            <p>正直创造善意，创造信任，创造自我价值感。这让我们感觉到我们的环境是友善的，周围的人是值得信赖的，我们自身和我们的工作是正义的，有价值的，它让我们站的更高，看的更远。不论这是否会带来金钱上的利益，它带给我们主观上的感受都是更好的。  </p>
                        </div>
                    </div>
                    <div className='img_wrapper'>
                        <img alt='img1' className='textImg' src={'https://www.higgsasset.com/img/web/49.jpg'} />
                        <div className="t">开放</div>
                    </div>
                    <div className="desc">
                        <div className="dtl">
                            <div className="title b">信息开放</div>
                            <p>希格斯投资会公开员工做出个人决策所需要的公司整体信息，其中包括发展战略，工作目标，人员结构，财务状况，决策流程等等。 </p>
                            <p >我们还会尽最大可能公开研究和工作成果相关信息。作为一家量化投资机构，我们越是能扩大分享范围，便越有竞争力。</p>
                        </div>
                        <div className="dtl">
                            <div className="title b">思想开放</div>
                            <p>希格斯投资对新的事实，新的知识，新的观点等持开放的、探索的态度。希格斯投资支持大家放开去思考、去体验、去实践。我们倡导先发散，再聚焦的工作方法，鼓励大家就问题畅所欲言；我们有各种各样的讨论班，供大家在一起学习新知识；我们还有各种自发的丰富的社团性的体育活动，包括健身，滑板，羽毛球，壁球，网球等等，供大家去体验和锻炼。 </p>
                            <p>思想开放还意味着我们需要意识到自己有可能是错的，并且一直探索和评估我们出错的可能性。在科学研究的道路上，出错既不应该让人感觉害羞，也不应该让人感觉害怕，相反，不愿意承认自己会出错才是值得害羞和害怕的。 </p>
                        </div>
                        <div className="dtl">
                            <img alt='img1' className='img' src={img195} />
                        </div>
                    </div>
                    <div className='img_wrapper'>
                        <img alt='img1' className='textImg' src={'https://www.higgsasset.com/img/web/40.jpg'} />
                        <div className="t">求真</div>
                    </div>
                    <div className="desc">
                        <div className="dtl">
                            <div className="title  b">思维方式</div>
                            <p>大脑会通过一些思维捷径，以我们难以注意到的方式，把我们引导到不符合客观事实的认知上去。日常生活之中，我们不可避免的会使用这些思维捷径，但在重要问题上，我们努力分清楚我们的认知是通过思维捷径得来的，还是通过对问题和事实的仔细分析和推理得来的。 </p>
                            <p>在工作中我们应当知道我们采用某个做法的道理，而不是：</p>
                            <ul>
                                <li>我这么做是因为某某（代表某权威）让我这么做；</li>
                                <li>我这么做是因为一直都是这么做的；</li>
                                <li>我这么做是因为其他人在这么做。</li>
                            </ul>
                            <p>我们不是为了挑战权威而挑战权威，也不是为了改变传统而改变传统，更不是为了特立独行而特立独行。但当我知道这么做有道理的时候，权威、传统和大众又怎么能影响到我们呢？</p>
                        </div>
                        <div className="dtl">
                            <div className="title  b">工作方法</div>
                            <p>我们通过极力减少团队成员之间的权力距离，来创造一个事实能够得到充分反映的工作氛围。希格斯投资的办公空间是开放的，办公设施是平等的，会议的座次是自由随性的。这鼓励每个人都自由的说出自己的想法，我们也珍视每个人的观点与看法，这让我们的思想更加接近于真相。 </p>
                        </div>
                        <div className="dtl">
                            <div className="title b">认识自我</div>
                            <p>自我是获得有关自我真相的一道屏障。无论是个人，还是组织，要想真正的获得成功，都需要知道自身的价值和热情所在，需要清楚的理解自身的优势和不足。我们从日常生活中的嘈杂喧嚣之中走出来，倾听自己内心深处的声音；从消极的自我防卫中走出来，倾听他人的反馈。这时我们认识到的自我不再是一个经过粉饰的自我，而是更深层次的对自身的接纳，我们的行为不再被广告和潮流所左右，而是真正明白了自身的追求。 </p>
                        </div>
                    </div>
                    <div className='img_wrapper'>
                        <img alt='img1' className='textImg' src={'https://www.higgsasset.com/img/web/41.jpg'} />
                        <div className="t">求精</div>
                    </div>
                    <div className="desc">
                        <div className="dtl">
                            <img alt='img1' className='img' src={img196} />
                        </div>
                        <div className="dtl">
                            <div className="title b">志存高远</div>
                            <p>当人生有了目标，也就有了意义，而远大的目标赋予人生以高远的意义。目标并不能让人生从此一帆风顺，失望、挫折、困难、挑战仍然会接踵而至。坚定的目标指引我们从失望和挫折中走出来，指引我们战胜困难，克服挑战。这让我们的人生更加丰富，更加有层次。 </p>
                        </div>
                        <div className="dtl">
                            <div className="title b">有美感的工作</div>
                            <p>当我们的工作质量达到了一定的高度，我们就会在工作中发现美感，也可以体验到很高的成就感。这一点在庄子的名篇《庖丁解牛》中有精彩的描述 </p>
                            <p>“庖丁为文惠君解牛，手之所触，肩之所倚，足之所履，膝之所踦，砉然向然，奏刀騞然，莫不中音。合于《桑林》之舞，乃中《经首》之会。” </p>
                            <p>庖丁解牛的动作和发出的声音，就像舞蹈和音乐一样优美。而当庖丁应用出神入化的刀法完成解牛的工作之后，他 </p>
                            <p>“提刀而立，为之四顾，为之踌躇满志，善刀而藏之。” </p>
                            <p>我们不把工作看做是人人都不愿意做，但是又不得不做的事情。工作是我们用人生去雕琢的艺术品，我们用自己的努力成就工作，而精美的工作又反过来成就我们。 </p>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Culture;