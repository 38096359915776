import React, { Component } from 'react';
import Footer from '../component/Footer/footer'
import './afternoon_tea.scss'
import img194 from '../assets/img/194.jpg'

class Tea extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <img alt='img1' className='mission_Img' src={img194} />
                <div className="mission_content">
                    <div className="left">希格斯下午茶</div>
                    <div className="right">
                        <p>要说最能激发全体希格斯同学的兴趣的传统活动，可能下午茶是一个有力的竞争者。希格斯的下午茶不像英式下午茶那样优雅精致，也并不一定有茶可喝，而是下午三四点钟的时候，一批零食驾到，大家在一块吃水果，吃蛋糕，甚至是撸烤串，剥小龙虾。大家一边吃东西，一边打趣揶揄，欢声笑语，其乐融融。</p>
                        <p>希格斯投资并不是每天都有下午茶，这却增加了下午茶的乐趣。我们常常会借助一些事件给我们自己以享受轻松一刻的机会，例如，有新人入职，给同学们过集体生日，有同学当上了新娘子或者新郎官，工作上取得了重要进展等等。</p>
                        <p>希格斯投资从来不把工作上犯错当做严重的事件来对待，若有人真的有失误，以至于自己都觉得不好意思了，一般的做法也是请大家吃一顿下午茶吧。</p>
                    </div>
                </div>
                <Footer type={2}></Footer>
            </div>
        );
    }
}

export default Tea;