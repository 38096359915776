import React, { Component } from 'react';
import './workNotion.scss'
import Footer from '../component/Footer/footer'
import store from '../store/index';

import { changeIndex } from '../store/actionCreator'
class WorkNotion extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        store.dispatch(changeIndex(this.props.location.pathname))
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <img alt='img1' className='mission_Img' src='https://www.higgsasset.com/img/web/33.jpg' />
                <div className="mission_content">
                    <div className="left">我们的工作观</div>
                    <div className="right">
                        <p className='title fir'>体验工作之乐</p>
                        <p>工作是什么？很多人认为工作是支持生活的手段，是等到财务自由之后需要摆脱的事情。但这种看法与事实之间的差距不可能更远了</p>
                        <p>你几乎不可能看到一个终日无所事事，但是觉得自己的生活非常值得向往的人，相反，这种人常常被日常的无聊所击垮，不得不沉迷于各种刺激物之中，而这些刺激物带来的感觉绝不是幸福。与此同时，你见到的那些生活幸福的人，那些值得尊敬的人，那些觉得自己的一生有价值的人，往往是那些能够充分享受工作之乐的人。</p>
                        <p>工作给我们以目标，而目标赋予人生以意义。每天的生活本来杂乱无章，像噪音一样嗡嗡作响。一旦抱持了工作的目标，这些杂乱无章就仿佛有了主题，有了韵律，变成了一首正在谱写的乐曲。这样的人生也会遇到挑战，但与挑战而伴随的成长快感无与伦比；这样的人生也会遇到挫折，但是挫折不再是无底深渊，而是进身之阶。</p>
                        <p>工作之乐不是一种享乐，而是一种乐趣。享乐召之即来，乐趣却不是。享乐不需要意识的主动参与，乐趣却需要。享乐在片刻占满你的大脑之后，留下的是空虚，乐趣是在给你挑战之后，留下无尽的回味。</p>
                        <p>别人看我忙忙碌碌，但实际上我正乐在其中。</p>
                        <p className='title'>专注</p>
                        <p>在希格斯投资，我们不需要大家经常加班，我们高度鼓励大家在工作的时候保持高度的专注。</p>
                        <p>一个人能否充分享受工作之乐，非常取决于他/她的专注能力。当我们完全专注于工作之时，仿佛忘记了时间的流逝，这中间的乐趣非等闲可比。相反，如果不能沉浸于工作，时时有其他念头来侵扰，就很难享受工作的过程。其实何止工作如此，一个人能否享受生活之美，何尝不在于他/她是否能够专注于当下。</p>
                        <p>一时的专注带来乐趣，一生的专注带来幸福。杜甫称赞唐代画家曹霸“丹青不知老将至”，杜甫对这一点刻画多么的传神！无论曹霸是否取得了世俗上的成功，但他的一生无疑是相当幸福的。</p>
                        <p className='title'>做成很大事情的方法</p>
                        <p>做成很大事情的方法，就是不要试图一下子就做成很大的事情。</p>
                        <p>这句话是模仿著名哲学家洛克的名言：“学到很多东西的诀窍，就是不要一下子学很多东西”。</p>
                        <p>每天的进展看起来毫不起眼，但是经年累月下来，就已经走到了当初完全不曾想到的地方。而试图一步登天，却往往带来的是心浮气躁，半途而废。把注意力放在当下，不虑过去，不忧将来，这种方法看起来很慢，却是做成大事的不二法门。</p>
                        <p className='title'>我们的工作设计</p>
                        <p>希格斯投资致力于为每位员工提供有前景，有锻炼，有回馈的工作，让每个人都能充分体会到工作之乐。</p>
                        <p>如果一份工作枯燥无聊，不能助人成长，不能达成有意义的结果，那是工作设计的失职。希格斯投资不但要成就事业，更要成就每一个在希格斯投资工作的人。</p>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default WorkNotion;