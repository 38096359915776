import React, { Component } from 'react'
import Footer from '../component/Footer/footer'
import './sports.scss'
import img183 from '../assets/img/183.jpg'
import img184 from '../assets/img/184.jpg'
import img185 from '../assets/img/185.jpg'
class Sports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: false
        }
    }
    componentDidMount() {
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        const { mobile } = this.state
        return (
            <div>
                <img alt='img1' className='mission_Img' src={img183} />
                {mobile ? <div className='mobile_com'>
                    <div className='title'>希格斯投资的体育活动</div>
                    <p>很多项目的体育活动，你都能在希格斯投资找到很好的玩伴，例如滑板、网球、篮球，健身，长跑，甚至跑酷等等。不过希格斯投资最有特色的还是希格斯投资的“羽毛球联赛”。</p>
                    <img alt='img1' className='img' src={img184} />
                    <div className="right">
                        <img alt='img1' className='img1' src={img185} />
                    </div>
                    <p>希格斯投资的羽毛球联赛分为常规赛和季后赛，常规赛是循环赛，季后赛是淘汰赛，季后赛冠军即一个赛季的总冠军。每个周六下午，大家来到球场，有比赛安排的选手就你来我往，分个高下。没有比赛的同学们可以观战助威，可以与网对面的同学随便挥几拍，也可以来个不太正式的计分对战。很多同事不但自己过来，还把家属带过来一块玩。一个下午的汗水挥洒，心情好不轻松畅快。</p>
                </div> :
                    <div className="competition_content">
                        <div className='title'>希格斯投资的体育活动</div>
                        <div className="top">
                            <div className="right">
                                <p>很多项目的体育活动，你都能在希格斯投资找到很好的玩伴，例如滑板、网球、篮球，健身，长跑，甚至跑酷等等。不过希格斯投资最有特色的还是希格斯投资的“羽毛球联赛”。</p>
                                <img alt='img1' className='img' src={img184} />
                            </div>
                            <div className="left">
                                <img alt='img1' className='img' src={img185} />
                                <p>希格斯投资的羽毛球联赛分为常规赛和季后赛，常规赛是循环赛，季后赛是淘汰赛，季后赛冠军即一个赛季的总冠军。每个周六下午，大家来到球场，有比赛安排的选手就你来我往，分个高下。没有比赛的同学们可以观战助威，可以与网对面的同学随便挥几拍，也可以来个不太正式的计分对战。很多同事不但自己过来，还把家属带过来一块玩。一个下午的汗水挥洒，心情好不轻松畅快。</p>
                            </div>
                        </div>
                    </div>
                }
                <Footer></Footer>
            </div>
        );
    }
}

export default Sports;