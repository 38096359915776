import React, { Component } from 'react'
import Footer from '../component/Footer/footer'
class Zju extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
        window.scrollTo(0,0)
    }

    render() {
        return (
            <div>
                <img alt='img1' className='mission_Img' src={'https://www.higgsasset.com/img/web/78.jpeg'} />
                <div className="scholarship_wrapper">
                    <div className="title">浙江大学创新活动奖学金</div>
                    <div className='con'>
                        <p>I want to put a ding in the universe.</p>
                        <p className='right'>——史蒂芬·乔布斯</p>
                        <p>There’s a way to do it better——find it.</p>
                        <p className='right'>——托马斯·爱迪生</p>
                    </div>
                    <div className="con">
                        <p>“恰同学少年，风华正茂，书生意气，挥斥方遒。”这种想动不想静的冲动驱使着我们，看看能做出哪些与众不同的事情？</p>
                        <p>希格斯投资在浙江大学计算机学院捐赠创新活动奖学金，是想要鼓励那些想要创造不平凡的心灵，那些善于发现的眼睛，和那些勤劳灵巧的双手。奖学金的评选对象是浙江大学计算机学院的全日制在读本科生和研究生。如果参评者拥有自主开发的，具有较强计算机专业性与较高开放创新性的有意义或者有趣的项目，均有机会获奖。</p>
                    </div>
                </div>
                <Footer type={1}></Footer>
            </div>
        );
    }
}

export default Zju;