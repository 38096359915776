import React, { useState } from 'react'
import './faq.scss'

export default ({ item, type, key }) => {
    const [showDesc, setShowDesc] = useState(false)
    const toggleFold = () => {
        setShowDesc(!showDesc)
    }

    return (
        <div className='fapWrapper'>
            <div className="headss" onClick={toggleFold}>
                <div className="name">Q  {item.name}</div>
                <div className="direction">{item.direction}</div>
                {/* <div className="fold" >
                            {showDesc ?
                                <img alt='img1' className='arr_d' src={down} /> :
                                <img alt='img1' className='arr_r' src={right} />}
                        </div> */}
            </div>
            {showDesc &&
                <div className="desc">
                    <div className="detail">
                        <div className='con'>

                            <div className="answer"> A</div>

                            <div className="content">
                                {item.detail1 && item.detail1.map((info, index) => {
                                    return (
                                        <>
                                            {info.title ? <div className='texts' key={index}> {info.title}  </div> :
                                                <a href='https://mp.weixin.qq.com/s?__biz=Mzg5NDIzODIwOQ==&mid=2247486634&idx=1&sn=605592b2052d9f55254bad06548607ca&chksm=c023e7c4f7546ed28cbc5e3be9019cc833920b73a5ed56f94ebec8392cc6fd294ea82bf90fa5&mpshare=1&scene=1&srcid=0810UUrQ3pY2sgb3bOWB6GVJ&sharer_sharetime=1597056226469&sharer_shareid=a1b48f46e7e00468458d9fc8fc730f0c&key=519b8bbaea533f94002d2e08650b9d9a1605e7cd6f12494c38882024a57f6da86a5413eb28f0eef3c576b369385099ff9cb08831fce2e69cc5624f233f129657e85b215c509b08b65da7cefbdd5cddcc21220590ad9e2ab33e1cb73557e578f12bcfce27b9399c133fdea61e6803b3d26132eb74a944ff77ff0cbd1f4aef331e&ascene=1&uin=MTAwNDYwNDYwOQ%3D%3D&devicetype=Windows+10+x64&version=62090538&lang=zh_CN&exportkey=A07QAG0OBxfJdpfoDYf3Uj4%3D&pass_ticket=5HkJhuMmvjN6DLkpLYFKyUGtdqOZICmouY%2FCG3FrM6wM0qTIHWZplvKfCnnpw7kX&wx_header=0' className='texts' target='_blank' key={index}>{info.titles}  </a>}
                                        </>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}