import React, { Component } from 'react';
import Footer from '../component/Footer/footer'
import './scholarship.scss'
class Scholarship extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div >
                <img alt='img1' className='mission_Img' src={'https://www.higgsasset.com/img/web/72.jpeg'} />
                <div className="scholarship_wrapper">
                    <div className='title'>北京大学数学学院钱敏平奖学金</div>
                    <div className='con'>
                        <p>Never lose a holy curiosity.</p>
                        <p className='right'>——爱因斯坦</p>
                        <p>Science curiosity is a desire to seek out and consume scientific information just for the pleasure of doing so. People who are science curious do this because they take satisfaction in seeing what science does to resolve mysteries. That is different from somebody who would show interest in scientific information because they had a specific goal like wanting to do well in school. Science-curious people are driven by the pure activity of consuming what science knows.</p>
                        <p className='right'>——Dan Kahan，耶鲁大学心理学教授</p>
                    </div>
                    <div className='con'>
                        <p>由希格斯投资捐赠的北京大学数学学院钱敏平奖学金奖励致力于思考和解决原创性问题的硕士和博士研究生，以及高年级本科生。</p>
                        <p >奖项重点关注所研究的问题本身是一个好问题，有意义、有趣、有原创性，而研究者在这个问题上取得的实际进展相对较为次要。同时，研究者需要展现出对此问题全情投入的精神，研究者被问题和解决问题的过程所吸引，而不是主要受到相关成败得失的驱使。</p>
                        <p>希格斯投资希望通过这样的奖学金项目，让大家在发表论文的压力之余，再次聚焦于研究的乐趣本身，并且通过榜样的力量使得大家能从日常生活中跳脱出来看的更远。相信这样的奖学金项目对于培养学生终身的好奇心和探索精神会有帮助。</p>
                    </div>
                    <div className="intr">
                        <div className="left">
                            <div className="title">钱敏平先生简介</div>
                            <p>钱敏平，女，1939出生，北京大学数学科学学院教授。专于数学随机过程理论的研究，并应用于生物和智能计算的研究。钱敏平先生与其兄长钱敏共同提出马氏过程的环流理论及熵产生的概率定义。著有《应用随机过程——模型与方法》，《随机微分方程引论》等。</p>
                            <p>钱敏平年逾八十，但依然思路敏捷，兴趣广泛，治学上孜孜不倦，她本人正是那种在科学中找到宁静与安定的典范。</p>
                        </div>
                        <div className="right"></div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Scholarship;