import React, { useState } from 'react'
import './delivery_method.scss'
import { Button, Modal, Input, Empty } from 'antd';
import axios from 'axios';
import DeliveryProcess from '../../../assets/img/delivery_process.png'
import { set } from 'core-js/core/dict';

export default ({ mobile }) => {
    const [showProcess, setShowProcess] = useState(false)
    const [searchEmail, setSearchEmail] = useState('')
    const [showSearchResultEmpty, setShowSearchResultEmpty] = useState(false)
    const [showSearchResult, setShowSearchResult] = useState(false)
    const [searchMsg, setSearchMsg] = useState('')
    const [searchData, setSearchData] = useState({})

    const clickShowProcess = () => {
        setShowProcess(!showProcess)
        setShowSearchResultEmpty(false)
        setShowSearchResult(false)
    }

    const searchProcess = () => {
        axios.post('https://www.higgsasset.com/higgs/web/api/checkResumeProgress', {
            email: searchEmail
        }).then(res => {
            const { code, data, msg } = res.data
            if (code == 1) {
                setShowSearchResult(false)
                setShowSearchResultEmpty(true)
                setSearchMsg(data)
            }
            if (code == 0) {
                setShowSearchResult(true)
                setShowSearchResultEmpty(false)
                setSearchData(data)
            }
        })
    }

    const handleInput = (e) => {
        setSearchEmail(e.target.value)
    }

    return (
        <div className='resume'>
            <ul className='con'>
                <li className='con_wrap'>
                    <div className='title'>投递格式</div>
                    <div className='desc'>简历名称：姓名-毕业院校-全职/实习-到岗时间(应聘实习岗位的同学请备注可实习时长与到岗时间)</div>
                </li>
                <li className='con_wrap'>
                    <div className='title'>投递方式</div>
                    <div className='desc'>官网投递：<a href="https://www.higgsasset.com/">https://www.higgsasset.com/</a></div>
                    <div className='desc'>小程序投递: 搜索 希格斯小程序</div>
                </li>
                {/* TODO 有待商榷 暂不展示 */}
                {/* <li className='con_wrap'>
                    <div className='title'>投递流程</div>
                    {mobile ? <img src={DeliveryProcess} alt="" style={{ width: '100%' }} /> :
                        <img src={DeliveryProcess} alt="" style={{ width: 556, height: 288 }} />
                    }
                </li> */}
                <li className='con_wrap'>
                    <div className='title'>投递进度查询</div>
                    <div className='desc'>
                        <Button
                            type="primary"
                            style={{ marginBottom: 14, background: "#0059A5", borderRadius: 10 }}
                            onClick={clickShowProcess}
                        >
                            投递进度查询
                        </Button>

                    </div>
                </li>
            </ul>

            {/* 弹窗进行进度查询 */}
            {showProcess &&
                <Modal
                    title="投递进度查询"
                    open={showProcess}
                    okText='查询'
                    cancelText='取消'
                    onOk={searchProcess}
                    onCancel={clickShowProcess}
                >
                    <Input placeholder="请输入邮箱进行查询" onChange={handleInput} />
                    {showSearchResultEmpty &&
                        <Empty
                            style={{ marginTop: 20 }}
                            description={
                                <span>
                                    {searchMsg}
                                </span>
                            }
                        />
                    }
                    {showSearchResult &&
                        <div style={{ paddingTop: 20 }}>
                            {searchData.name && <div>姓名：{searchData.name}</div>}
                            {searchData.position && <div>岗位：{searchData.position}</div>}
                            {searchData.state && <div>状态：{searchData.state}</div>}
                            {searchData.desc && <div>描述：{searchData.desc}</div>}
                        </div>
                    }
                </Modal>}
        </div>
    )
}