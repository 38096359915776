import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import Footer from '../component/Footer/footer'
import img155 from '../assets/img/155.jpg'
import img157 from '../assets/img/157.jpg'
import img156 from '../assets/img/156.jpg'
import img154 from '../assets/img/154.jpg'
import img153 from '../assets/img/153.jpg'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'
import './work.scss'

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false
    }
  }
  componentDidMount() {
    store.dispatch(changeIndex(this.props.location.pathname))
    if (document.body.clientWidth < 1000) {
      this.setState({
        mobile: true
      })
    } else {
      this.setState({
        mobile: false
      })
    }
  }
  componentWillUnmount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { mobile } = this.state
    return (
      <div>
        <img alt='img1' className='workImg' src={img155} />
        <div className='work_content'>
          <div className='title'>专心致志，浑然忘我</div>
          <div className='text'>拥有有乐趣的工作，才拥有有乐趣的人生。</div>
          <div className='text'>希格斯投资致力于提供有前景，有锻炼，有回馈的工作，让每个人都能充分体会到工作之乐。</div>
          <div className='link-wrap'>
            <Link to='/workNotion/' className={`link ${mobile}`}>希格斯投资的工作观</Link>
          </div>
        </div>
        <img alt='img1' className='workImg' src={img157} />
        <div className='work_content'>
          <div className='title'>开放、舒畅、交流、碰撞</div>
          <div className='link-wrap'>
            <Link to='/environment/' className={`link ${mobile}`}>探索希格斯投资的工作环境</Link>
          </div>
        </div>
        <img alt='img1' className='workImg' src={img156} />
        <div className='work_content'>
          <div className='title'>奇文共欣赏，疑义相与析。</div>
          <div className='text'>了解希格斯投资的传统文化之一</div>
          <div className='link-wrap'>
            <Link to='/seminar/' className={`link ${mobile}`}>讨论班</Link>
          </div>
        </div>
        <img alt='img1' className='workImg' src={img154} />
        <div className='work_content'>
          <div className='title'>敞开心胸，尽情挥洒</div>
          <div className='text'>丰富多彩的体育活动，给你拼搏的精神和强健的体魄</div>
          <div className='link-wrap'>
            <Link to='/sports/' className={`link ${mobile}`}>了解希格斯投资的体育活动</Link>
          </div>
        </div>
        <img alt='img1' className='workImg' src={img153} />
        <div className='work_content'>
          <div className='title'>共享这美好的一刻</div>
          <div className='text'>多年以来，为所有希格斯人喜闻乐见的活动</div>
          <div className='link-wrap'>
            <Link to='/tea/' className={`link ${mobile}`}>下午茶</Link>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Work;