import React, { useState } from 'react'
import './contact_way.scss'
import WXIMG from '../../../assets/img/contact-wx2.jpg'
import FriendIMG from '../../../assets/img/contact-friends.png'

export default ({ mobile }) => {
    return (
        <div className='resume'>
            <ul className='con'>
                <li className='con_wrap'>
                    <div className='title'>电话：0571-87248912</div>
                </li>
                <li className='con_wrap'>
                    <div className='title'>邮箱：recruitment@higgsasset.com    </div>
                </li>
                <li className='con_wrap'>
                    <div className='title' style={{ marginBottom: 24 }}>工作地址：浙江省杭州市滨江区滨康路聚才大厦2号楼29楼</div>
                    <div className='title'>更多招聘详情，欢迎关注希格斯公众号/添加 希格斯人才伙伴</div>

                    <div className={'img_wrapper1 ' + (mobile ? 'mobile' : 'pc')}>
                        <div className='gzh' >
                            <img src={WXIMG} alt="" style={{ width: 106, height: 106 }} />
                            <div style={{ textAlign: 'center' }} className='img_desc'>希格斯公众号</div>
                        </div>
                        <div>
                            <img src={FriendIMG} alt="" style={{ width: 106, height: 106 }} />
                            <div className='img_desc'>希格斯人才伙伴</div>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    )
}