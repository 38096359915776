import React, { Component } from 'react'

import './you_will_get.scss'

export default ( {mobile}) => {


    return (
        <div className={"you_will_get " +  (mobile ? 'mobile' : '')}>
            {/* <div className='leftTitle'>你将得到</div> */}

            <div className={'get_wrapper ' +  (mobile ? 'mobile' : '') }>
                <div className='item'>
                    <div className="title">多样的成长路径</div>
                    <div className="box"></div>
                    <div className="content">
                        <div className='texts'>精英学术研究氛围</div>
                        <div className='texts'>学科讨论班</div>
                        <div className='texts'>希格斯图书馆</div>
                        <div className='texts'>丰富团建活动</div>
                    </div>
                </div>
                <div className='item'>
                    <div className="title">优质的办公体验</div>
                    <div className="box"></div>
                    <div className="content">
                        <div className='texts'>无限量水果零食茶饮</div>
                        <div className='texts'>弹性上班制 </div>
                        <div className='texts'>专属带薪休假福利</div>
                    </div>
                </div>
                <div className='item'>
                    <div className="title">踏实的组织文化</div>
                    <div className="box"></div>
                    <div className="content">
                        <div className='texts'>专注的工作状态</div>
                        <div className='texts'>共同的成长信念</div>
                        <div className='texts'>终身学习的态度</div>
                    </div>
                </div>
                <div className='item'>
                    <div className="title">良好的发展空间</div>
                    <div className="box"></div>
                    <div className="content">
                        <div className='texts'> 扁平化管理 </div>
                        <div className='texts'> 竞争合伙人机会 </div>
                    </div>
                </div>
                <div className='item'>
                    <div className="title">优渥的经济回报</div>
                    <div className="box"></div>
                    <div className="content">
                        <div className='texts last'> 全职 </div>
                        <div className='texts last'> 极具竞争力的薪酬福利 </div>
                        <div className='texts last'> 丰厚的奖金制度 </div>
                        <div className='texts last'> 年度体检 ,各类节日补贴 </div>
                        <div className="texts last subtitle">实习</div>
                        <div className="texts last">具有竞争力的实习补贴</div>
                        <div className="texts last">户口/学校非杭州地区</div>
                        <div className="texts last">学生享受租房补贴</div>
                        <div className="texts last">与大牛导师接触</div>
                    </div>
                </div>


            </div>


            {/* <div className='title1'>1. 多样的成长途径：精英学术研究氛围、学科讨论班、希格斯图书馆、丰富团建活动；</div>
            <div className='title1'>2. 优质的办公体验：无限量水果零食茶饮、弹性上班制、专属带薪休假福利；</div>
            <div className='title1'>3. 踏实的组织文化：专注的工作状态、共同的成长信念、终身学习的态度；</div>
            <div className="title1">4. 良好的发展空间：扁平化管理、晋升合伙人的机会</div>
            <div className='title1'>5. 优渥的经济回报：</div>
            <div className='title1 indent'>全职：极具竞争力的薪酬福利、丰厚的奖金制度、年度体检、各类节日补贴；</div>
            <div className='title1 indent'>实习：具有竞争力的实习补贴、双非（户口/学校非杭州地区）学生享受租房补贴、与大牛导师接触快速成长的机会、表现优异者可转正；</div> */}
        </div >

    )
}