import React, { Component } from 'react';
import Footer from '../component/Footer/footer'
import './seminar.scss'
import discussion from '../assets/img/discussion.jpg'

class Seminar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <img alt='img1' className='mission_Img' src={discussion} />
                <div className="mission_content">
                    <div className="left">希格斯讨论班</div>
                    <div className="right">
                        <p>传承已久的“讨论班”是希格斯投资的一个特色。</p>
                        <p>在每次讨论班上，由一位同事担任老师，讲述一个新的内容，供其他人学习和讨论。老师讲述的内容往往与平时工作的内容没有直接关系，而是会拓展一些新的知识。例如，大家集体学习某本教材，或者报告一篇有意思的论文等等。</p>
                        <p>陶渊明有诗称“昔欲居南村，非为卜其宅。闻多素心人，乐与数晨夕”。陶渊明之所以迁移到南村，是因为那里的人们心地纯洁善良，他很喜欢与他们相处。在诗的末尾，陶渊明写道：“奇文共欣赏，疑义相与析”。</p>
                        <p>一千多年之后，我辈复得陶公之乐，不是人生一大幸事吗？</p>
                    </div>
                </div>
                <Footer type={2}></Footer>
            </div>
        );
    }
}

export default Seminar;