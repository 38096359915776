import React, { Component } from 'react';
import './fullPage.scss'
import { Link } from 'react-router-dom'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import { Carousel } from 'antd';
import Footer from '../Footer/footer'
import img201 from '../../assets/img/201.jpg'
import img203 from '../../assets/img/203.jpg'
import EXP from '../../assets/img/p2.png'
import uploadImg from '../../assets/img/joinus.png'

import {
    Form, Button, Input, message, Modal, Upload, Select, Radio
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

// import cookie from "react-cookies";

const { Option } = Select;

let kai = true, i = 0;
let kai2 = true, j = 0;
let timer1
let timer2
let timer3
let timer4
var timer = null;
let disabledButton
let timers

class FullPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: false,
            curIndex: 0,
            firstX: 0,
            firstY: 0,
            endX: 0,
            endY: 0,
            moveX: 0,
            moveY: 0,
            itemIndex: 0,
            showResumeModal: false,
            addData: {},
            mobile: false,
            fileList: [],
            showNote: false,
            disabledButton: true,
            countTime: ''
        }
    }

    componentDidMount() {
        let cur = this
        let flag = sessionStorage.getItem('showNote')
        if (flag == 'true') {
            cur.setState({
                showNote: false
            })
        } else {
            cur.setState({
                showNote: true
            })
        }
        window.addEventListener('mousewheel', this.handleScroll, true);
        window.addEventListener('DOMMouseScroll', this.handleScroll, true);
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
        disabledButton = setTimeout(function () {
            cur.setState({
                disabledButton: false
            })
        }, 5000);

        let time = 5
        timers = setInterval(function () {
            time--;
            cur.setState({
                countTime: time + '秒'
            })
            if (time == 0) {
                time = 6;
                clearInterval(timers);
            }
        }, 1000)
    }

    handleScroll = (ev) => {
        let oUl = document.getElementsByTagName("ul")[0];
        let weel = ev.wheelDelta || -ev.detail
        if (kai && oUl) {
            kai = false;
            oUl.style.transition = " 0.5s ease";
            if (weel < 0 && i < 3) {
                i++;
            }
            if (weel > 0 && i > 0) {
                i--;
            }
            timer1 = setTimeout(function () {
                oUl.style.top = -i * 100 + 'vh';
            }, 0);
            timer2 = setTimeout(function () {
                kai = true;
            }, 500);
            this.setState({
                curIndex: i
            })
        }
    }

    componentWillUnmount() {
        kai = true
        kai2 = true
        i = 0
        clearTimeout(timer1)
        clearTimeout(timer2)
        clearTimeout(timer3)
        clearTimeout(timer4)
        clearTimeout(timer)
        clearTimeout(timers)
        clearTimeout(disabledButton)
        this.setState({
            curIndex: 0
        })
    }

    changeItem = (e) => {
        this.setState({
            itemIndex: e
        })
    }

    notAgree = () => {
        window.location.href = 'about:blank';
        window.close()
    }

    goAgree = () => {
        this.setState({
            showNote: false
        })
        sessionStorage.setItem('showNote', true)
    }

    render() {
        const { curIndex, mobile, itemIndex, showNote, disabledButton, countTime } = this.state
        return (
            <div lang="scss" scoped className='fullPageWrap'>
                {mobile ?
                    <div className="mobile_fullpage">
                        <div className='wrap'>
                            <img alt='img45' className='fullImg' src={img203} />
                            <div className='posText '>
                                <div className='line-1'>我们生产更合理的价格</div>
                                <div className='line-2'>大海并不总是平的，因此才有冲浪者的存在。市场价格并非总是合理，因此才有希格斯投资的存在。</div>
                                <div className='line-3'>我们通过研究与交易，为市场贡献更合理的价格。</div>
                                <Link to='/mission/' className='link'>阅读“希格斯使命”</Link>
                            </div>
                        </div>
                        <div className='wrap'>
                            <img alt='img38' className='fullImg' src={EXP} />
                            <div className='posText '>
                                <div className='line-1'>像其他科学研究一样，我们进行大量的实验</div>
                                <div className='line-2'>正如科学家通过实验探索大自然的规律，希格斯投资也通过实验分析市场价格的变化规律，</div>
                                <div className='line-3'>只是我们的实验发生在各种各样的数据回测之中。</div>
                            </div>
                        </div>
                        <div className='wrap'>
                            <img alt='https://www.higgsasset.com/img/web/42.jpg' className='fullImg' src='https://www.higgsasset.com/img/web/42.jpg' />
                            <div className='posText black'>
                                <div className='line-1'>不断地质疑，正如大浪淘沙</div>
                                <div className='line-2'>在探索的过程中，我们犯过很多以假为真和以真为假的错误。</div>
                                <div className='line-3'>保持开放的心态，并以事实为准绳，才能不断从自我的迷雾中走出来，从而获得关于市场的真正的知识。</div>
                                <Link to='/culture/' className='link dark'>阅读“希格斯文化”</Link>
                            </div>
                        </div>
                        <div className='wrap'>
                            <img alt='img36' className='fullImg' src='https://www.higgsasset.com/img/web/36.jpeg' />
                            <div className='posText'>
                                <div className='line-1'>技术奠定进步之阶</div>
                                <div className='line-2'>从数据挖掘系统到程序化交易平台，从高性能计算集群到高速交易服务器，</div>
                                <div className='line-3'>从风险控制系统到交易监控系统，希格斯投资利用大量的信息技术和设施为研究和交易提供支撑。</div>
                            </div>
                        </div>
                    </div> :
                    <div className="fullpage_wrapper" >
                        <ul>
                            <li>
                                {mobile ? <div>
                                    <img alt='img1' className='carImg' src={img203} />
                                </div> :
                                    <Carousel onChange={this.changeItem.bind(this)} interval={10000} showStatus={false} showIndicators={false} autoPlay emulateTouch infiniteLoop showThumbs={false} >
                                        <div>
                                            <img alt='img1' className='carImg' src={img203} />
                                        </div>
                                        <div>
                                            <img alt='img2' className='carImg' src={img201} />
                                            {/* <img alt='img2' className='carImg' src='https://www.higgsasset.com/img/web/34.jpg' /> */}
                                        </div>
                                        <div>
                                            <img alt='img2' className='carImg' src='https://www.higgsasset.com/img/web/35.jpeg' />
                                            {/* <img alt='img2' className='carImg' src={img204} /> */}
                                        </div>
                                    </Carousel>}
                                <div className={'posText ' + (itemIndex === 0 ? 'black' : '')} >
                                    <div className='line-1 '>我们生产更合理的价格</div>
                                    <div>
                                        <div className='line-2 '>大海并不总是平的，因此才有冲浪者的存在。市场价格并非总是合理，因此才有希格斯投资的存在。</div>
                                    </div>
                                    <div>
                                        <div className='line-3 '>我们通过研究与交易，为市场贡献更合理的价格。</div>
                                    </div>
                                    <Link to='/mission/' className='link '>阅读“希格斯使命”</Link>
                                </div>
                            </li>
                            <li>
                                <img className='fullImg' alt="123" src={EXP} />
                                <div className='posText '>
                                    <div className='line-1'>像其他科学研究一样，我们进行大量的实验</div>
                                    <div className='line-2'>正如科学家通过实验探索大自然的规律，希格斯投资也通过实验分析市场价格的变化规律，</div>
                                    <div className='line-3'>只是我们的实验发生在各种各样的数据回测之中。</div>
                                </div>
                            </li>
                            <li>
                                <img className='fullImg' alt="123" src='https://www.higgsasset.com/img/web/42.jpg' />
                                <div className='posText black'>
                                    <div className='line-1'>不断地质疑，正如大浪淘沙</div>
                                    <div className='line-2'>在探索的过程中，我们犯过很多以假为真和以真为假的错误。</div>
                                    <div className='line-3'>保持开放的心态，并以事实为准绳，才能不断从自我的迷雾中走出来，从而获得关于市场的真正的知识。</div>
                                    <Link to='/culture/' className='link dark'>阅读“希格斯文化”</Link>
                                </div>
                            </li>
                            <li>
                                <img className='fullImg' alt="123" src='https://www.higgsasset.com/img/web/36.jpeg' />
                                <div className='posText'>
                                    <div className='line-1'>技术奠定进步之阶</div>
                                    <div className='line-2'>从数据挖掘系统到程序化交易平台，从高性能计算集群到高速交易服务器，</div>
                                    <div className='line-3'>从风险控制系统到交易监控系统，希格斯投资利用大量的信息技术和设施为研究和交易提供支撑。</div>
                                    {curIndex === 3 && <Footer type={2}></Footer>}
                                </div>
                            </li>
                        </ul>
                    </div>
                }

                {/* 简历上传按钮 */}
                {mobile &&
                    <Link to='/recruit/' className='resumeUpload'>
                        <img alt='uploadImg' className='img' src={uploadImg} />
                        <div className='title'>加入我们</div>
                    </Link>}

                {/* 弹窗 */}

                {showNote &&
                    <Modal
                        title={
                            <div className="modal-title">重 要 提 示 </div>
                        }
                        centered
                        visible={showNote}
                        closable={false}
                        width={1000}
                        footer={
                            <div style={{ textAlign: 'center' }}   >
                                <Button disabled={disabledButton} style={{ marginRight: '50px', width: '150px' }} onClick={this.goAgree.bind(this)} type="primary">{disabledButton ? countTime : '同意并继续访问'}</Button>
                                <Button onClick={this.notAgree.bind(this)}>不同意并放弃</Button>
                            </div>
                        }
                    >
                        <p>在继续浏览本公司网站前，请您仔细阅读此重要提示，并在本页结尾根据您的具体情况进行选择。</p>
                        <div>在继续浏览本公司网站前，请您确认您或您所代表的机构为“合格投资者”。根据我国《私募投资基金监督管理暂行办法》的规定，“合格投资者”的标准如下：</div>
                        <div>一、具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：</div>
                        <div>1、净资产不低于1000万元的单位；</div>
                        <div>2、金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。</div>
                        <div>(前述“金融资产”包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。)</div>
                        <div>二、下列投资者视为合格投资者：</div>
                        <div>1、社会保障基金、企业年金等养老基金、慈善基金等社会公益基金；</div>
                        <div>2、依法设立并在基金业协会备案的投资计划；</div>
                        <div>3、投资于所管理私募基金的私募基金管理人及其从业人员；</div>
                        <div>4、中国证监会规定的其他投资者。</div>
                        <p>如果您继续访问或使用本网站及其所载信息及资料，即表明您声明及保证您或您所代表的机构为“合格投资者”，并将遵守对您适用的司法区域的有关法律及法规。如果您不符合“合格投资者”标准或不同意下列条款及相关约束，请勿继续访问或使用本网站及其所载信息及资料。</p>
                        <p>投资涉及风险，投资者应详细审阅基金的发售文件以获取进一步资料，了解有关投资所涉及的风险因素，并寻求适当的专业投资和咨询意见。基金净值及其收益存在涨跌可能，过往的产品业绩数据并不预示基金未来的业绩表现。本网站所提供的资料并非投资建议或咨询意见，投资者不应依赖本网站所提供的信息及资料作出投资决策。</p>
                        <p>本网站所刊载的所有信息及资料本公司可予以修订或更改，但毋须提前通知，本公司并不承诺定期更新本网站。</p>
                        <p>与本网站所载信息及资料有关的所有版权、专利权、知识产权及其他产权均为本公司所有。本公司概不向浏览该资料人士发出、转让或以任何方式转移任何种类的权利。</p>
                    </Modal>}

            </div>
        );
    }
}

export default FullPage;

