import React, { Component } from 'react'
import './environment.scss'
import Footer from '../component/Footer/footer'
import img186 from '../assets/img/186.jpg'
import img189 from '../assets/img/189.jpg'
import img190 from '../assets/img/190.jpg'
import img192 from '../assets/img/192.jpg'
import img193 from '../assets/img/193.jpg'
import environment from '../assets/img/environment.jpg'

class Environment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: false
        }
    }
    componentDidMount() {
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
    }

    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        const { mobile } = this.state
        return (
            <div>
                <img alt='img1' className='mission_Img' src={img186} />
                {mobile ? <div className='env_mobile'>
                    <div className="title">探索希格斯投资的工作环境</div>
                    <p>开放式的办公空间，大量的书写墙面很方便的随时与人交流</p>
                    <div className="right">
                        <img alt='img1' className='img circle' src={environment} />
                    </div>
                    <p className='b'>研读室</p>
                    <p>一盏台灯，一杯清茶，一个安静的空间，深入思考一个问题</p>
                    <img alt='img1' className='img rec' src={img190} />
                    <img alt='img1' className='img1' src={img189} />
                    <p className='b'>休息区</p>
                    <p>书香一缕，咖啡飘香，琴声悠扬</p>
                    <div className="right">
                        <img alt='img1' className='img circle' src={img193} />
                        <p>谁说办公室不能high？</p>
                        <p>看电影，办party</p>
                        <p>唱K！</p>
                    </div>
                    <p>要做好工作</p>
                    <p>更要过好生活</p>
                    <p>更衣室</p>
                    <p>淋浴间</p>
                    <p>母婴室帮助你随时切换</p>
                    <img alt='img1' className='img rec' src={img192} />
                </div> :
                    <div className="env_content">
                        <div className="title">探索希格斯投资的工作环境</div>
                        <div className="con">
                            <div className="left">
                                <p>开放式的办公空间，大量的书写墙面</p>
                                <p>很方便的随时与人交流</p>
                            </div>
                            <div className="right">
                                <img alt='img1' className='img circle' src={environment} />
                            </div>
                        </div>
                        <div className="con y">
                            <div className="left">
                                <img alt='img1' className='img rec' src={img190} />
                            </div>
                            <div className="right down">
                                <p className='b'>研读室</p>
                                <p>一盏台灯，一杯清茶，一个安静的空间，深入思考一个问题</p>
                            </div>
                        </div>
                        <div className="cons">
                            <div className="top">
                                <img alt='img1' className='img' src={img189} />
                            </div>
                            <div className="bot">
                                <p className='b'>休息区</p>
                                <p>书香一缕，咖啡飘香，琴声悠扬</p>
                            </div>
                        </div>
                        <div className="con last">
                            <div className="left down">
                                <p>母婴室帮助你随时切换</p>
                                <p>淋浴间</p>
                                <p>更衣室</p>
                                <p>更要过好生活</p>
                                <p>要做好工作</p>
                            </div>
                            <div className="right ">
                                <img alt='img1' className='img rec' src={img192} />
                            </div>
                        </div>
                        <div className="con last">
                            <div className="left">
                                <img alt='img1' className='img circle' src={img193} />
                            </div>
                            <div className="right">
                                <p>谁说办公室不能high？</p>
                                <p>看电影，办party</p>
                                <p>唱K！</p>
                            </div>
                        </div>
                    </div>
                }
                <Footer></Footer>
            </div>
        );
    }
}

export default Environment;