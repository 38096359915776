import React, { Component } from 'react';
import './relation.scss'
import { message } from 'antd';
import 'antd/dist/antd.css'
import Footer from '../component/Footer/footer'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'
class Relation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: ''
        }
    }

    componentDidMount() {
        store.dispatch(changeIndex(this.props.location.pathname))
        document.addEventListener("keydown", this.onKeyDown)
        let flag = sessionStorage.getItem('higgsLogin')
        if (flag == 'true') {
            this.props.history.push('/product/') 
        }
    }

    handleUserChange(event) {
        this.setState({
            userName: event.target.value
        })
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        })
    }

    onSubmit() {
        const user = this.state.userName
        const pass = this.state.password
        if (user === 'higgs' && pass === 'higgs123') {
            this.props.history.push('/product/')
            sessionStorage.setItem("higgsLogin", true)
        } else {
            message.error('用户名或密码错误！')
        }
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    onKeyDown = (e) => {
        if (e.keyCode == 13) {
            const user = this.state.userName
            const pass = this.state.password
            if (user === 'higgs' && pass === 'higgs123') {
                this.props.history.push('/product/')
                sessionStorage.setItem("higgsLogin", true)
            } else {
                message.error('用户名或密码错误！')
            }
        }
    }
    render() {
        const { userName, password } = this.state
        return (
            <div className='relation_wrapper' >
                <img alt='img1' className='bgcImg' src={'https://www.higgsasset.com/img/web/59.jpeg'} />
                <div className='loginIn'>
                    <div className="wraps">
                        <div className='input-title'>用户名</div>
                        <input
                            className='input-in'
                            name="numberOfGuests"
                            type="text"
                            value={userName}
                            onChange={this.handleUserChange.bind(this)} />
                        <div className='input-title'>密码</div>
                        <input
                            className='input-in'
                            name="numberOfGuests"
                            type="password"
                            value={password}
                            onChange={this.handlePasswordChange.bind(this)} />
                        <input
                            className='sub'
                            name="numberOfGuests"
                            type="submit"
                            value='我是合格投资者'
                            onClick={this.onSubmit.bind(this)}
                        />
                    </div>
                </div>
                <Footer type={2} ></Footer>
            </div>
        );
    }
}

export default Relation;