import React, { Component } from 'react'
import FullPage from '../component/fullPage/fullPage'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = store.getState();
    }

    componentDidMount() {
        store.dispatch(changeIndex(this.props.location.pathname))
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <FullPage></FullPage>
            </div>
        );
    }
}

export default Index;