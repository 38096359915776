import React, { Component } from 'react'
import './innvoation.scss'
import { Link } from 'react-router-dom'
import Footer from '../component/Footer/footer'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'
let kai = true, i = 0;
let timer1
let timer2

class Innovation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstX: 0,
            firstY: 0,
            endX: 0,
            endY: 0,
            moveX: 0,
            moveY: 0,
            mobile: false,
            curIndex: 0
        }
    }

    componentDidMount() {
        window.addEventListener('mousewheel', this.handleScroll);
        window.addEventListener('DOMMouseScroll', this.handleScroll, true);
        window.addEventListener('resize', this.onResize)
        store.dispatch(changeIndex(this.props.location.pathname))
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
    }
    handleScroll = (ev) => {
        let oUl = document.getElementsByTagName("ul")[0];
        let weel = ev.wheelDelta || -ev.detail
        if (kai && oUl) {
            kai = false;
            oUl.style.transition = " 0.5s ease";
            if (weel < 0 && i < 2) {
                i++;
            }
            if (weel > 0 && i > 0) {
                i--;
            }
            timer1 = setTimeout(function () {
                oUl.style.top = -i * 100 + 'vh';
            }, 0);
            timer2 = setTimeout(function () {
                kai = true;
            }, 500);
            this.setState({
                curIndex: i
            })
        }
    }
    componentWillUnmount() {
        kai = true
        i = 0
        clearTimeout(timer1)
        clearTimeout(timer2)
        this.setState({
            curIndex: 0
        })
        window.scrollTo(0, 0)
    }

    NavonTouchStart = (e) => {
        this.setState({
            firstX: e.targetTouches[0].clientX,
            firstY: e.targetTouches[0].clientY,
        })
    }

    NavonTouchMove = (e) => {
        let oUl = document.getElementsByTagName("ul")[0];
        this.setState({
            endX: e.changedTouches[0].clientX,
            endY: e.changedTouches[0].clientY,
        });
        var moveX = this.state.endX - this.state.firstX;
        var moveY = this.state.endY - this.state.firstY;
        if (Math.abs(moveX) > 100 || Math.abs(moveY) > 100) {
            if (Math.abs(moveX) > Math.abs(moveY)) {
            } else {
                kai = false;
                oUl.style.transition = " 0.5s ease";
                if (moveY < -200 && i < 2) {
                    i++;
                }
                if (moveY > 200 && i > 0) {
                    i--;
                }
                timer1 = setTimeout(function () {
                    oUl.style.top = -i * 100 + 'vh';
                }, 0);
                timer2 = setTimeout(function () {
                    kai = true;
                }, 500);
                this.setState({
                    curIndex: i
                })
            }
        }
    }


    render() {
        const { mobile, curIndex } = this.state
        return (
            <>
                {mobile ? <div className="moblie_inn">
                    <ul >
                        <li >
                            <div className='posText black'>
                                <div className='line-1'>“希格斯杯”中国研究生</div>
                                <div className='line-1'>人工智能创新大赛浙江大学校内选拔赛</div>
                                <Link to='/competition/' className='link'>了解更多</Link>
                            </div>
                            <div className="img">
                                <img className='fullImg' alt="123" src={'https://www.higgsasset.com/img/web/71.jpeg'} />
                            </div>
                        </li>
                        <li >
                            <div className='posText black'>
                                <div className='line-1'>做这项研究是因为它很重要,很有趣</div>
                                <div className='line-1'>不是因为它会成功</div>
                                <Link to='/scholarship/' className='link'>查看希格斯投资捐赠的北京大学“钱敏平奖学金”</Link>
                            </div>
                            <div className="img">
                                <img className='fullImg' alt="123" src={'https://www.higgsasset.com/img/web/75.jpg'} />
                            </div>
                        </li>
                        <li >
                            <div className='posText black'>
                                <div className='line-1'>运用双手，</div>
                                <div className='line-1'>看看能做出什么有意思的东西。</div>
                                <Link to='/zju/' className='link'>查看浙江大学计算机学院创新奖学金</Link>
                            </div>
                            <div className="img">
                                <img className='fullImg' alt="123" src={'https://www.higgsasset.com/img/web/76.jpg'} />
                            </div>
                            {curIndex === 2 && <Footer type={2}></Footer>}
                        </li>
                    </ul>
                </div> :
                    <div
                        // onTouchStart={this.NavonTouchStart.bind(this)}
                        // onTouchMove={this.NavonTouchMove.bind(this)}
                        className="innovation_wrapper">
                        <ul >
                            <li >
                                <div className='posText black'>
                                    <div className='line-1'>“希格斯杯”中国研究生</div>
                                    <div className='line-1'>人工智能创新大赛浙江大学校内选拔赛</div>
                                    <Link to='/competition/' className='link'>了解更多</Link>
                                </div>
                                <div className="img">
                                    <img className='fullImg' alt="123" src={'https://www.higgsasset.com/img/web/71.jpeg'} />
                                </div>
                            </li>
                            <li >
                                <div className='posText black'>
                                    <div className='line-1'>做这项研究是因为它很重要,很有趣</div>
                                    <div className='line-1'>不是因为它会成功</div>
                                    <Link to='/scholarship/' className='link'>查看希格斯投资捐赠的北京大学“钱敏平奖学金”</Link>
                                </div>
                                <div className="img">
                                    <img className='fullImg' alt="123" src={'https://www.higgsasset.com/img/web/75.jpg'} />
                                </div>
                            </li>
                            <li >
                                <div className='posText black'>
                                    <div className='line-1'>运用双手，</div>
                                    <div className='line-1'>看看能做出什么有意思的东西。</div>
                                    <Link to='/zju/' className='link'>查看浙江大学计算机学院创新奖学金</Link>
                                </div>
                                <div className="img">
                                    <img className='fullImg' alt="123" src={'https://www.higgsasset.com/img/web/76.jpg'} />
                                </div>
                                {curIndex === 2 && <Footer type={2}></Footer>}
                            </li>
                        </ul>
                    </div>}


            </>
        );
    }
}

export default Innovation;

