import React, { Component } from 'react';
// import FullPage from './component/fullPage/fullPage'
import Header from './component/Header/header'
// import Footer from './component/Footer/footer'

class App extends Component {
  
  render() {
    return (
      <>
        <Header></Header>
          {this.props.children}
        {/* <Footer></Footer> */}
        {/* <FullPage></FullPage> */}
      </>
    );
  }
}

export default App;


