import React, { Component } from 'react';
import './list.scss'
import { Button } from 'antd';
import down from '../../assets/img/down.png'
import right from '../../assets/img/right.png'
import { PlusOutlined } from '@ant-design/icons';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDesc: false,
            mobile: false,
            showIndex: -1
        }
    }

    componentDidMount() {
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
    }

    toggleFold() {
        this.setState({
            showDesc: !this.state.showDesc
        })
    }

    toggleFoldSecond(index) {
        let name = `show${index}`
        this.setState({
            [name]: !this.state[name]
        })
    }

    clickShowResume = () => {
        this.props.onShowResume()
    }

    render() {
        const { showDesc, mobile, showIndex } = this.state
        const { item, type, key, recruitIndex } = this.props


        return (
            <div className="list" >
                {type === 1 && <div>
                    <div className={'topTitleWrapper ' + (recruitIndex % 2 == 0 ? "" : "right")} >
                        <div className='topTitle'>{item.level1}</div>
                    </div>
                    {item.list.map((infos, index) => {
                        return (
                            <div>
                                <div className={"heads second " + (recruitIndex % 2 == 0 ? "left" : "right")} onClick={this.toggleFoldSecond.bind(this, index)}>

                                    {recruitIndex % 2 == 0 ?
                                        <>
                                            <div className="fold" >
                                                {this.state[`show${index}`] ?
                                                    <img alt='img1' className='arr_d' src={down} /> :
                                                    <img alt='img1' className='arr_r' src={right} />}
                                            </div>

                                            <div className={"name " + (this.state[`show${index}`] ? 'choose' : '')}>{infos.level2}
                                                <span>
                                                    {infos.recruit_type == 1 && '（全职）'}
                                                    {infos.recruit_type == 2 && '（实习）'}
                                                    {infos.recruit_type == 3 && '（全职/实习）'}
                                                </span>
                                            </div>
                                        </> :
                                        <> <div className={"name " + (this.state[`show${index}`] ? 'choose' : '')}>{infos.level2}
                                            <span>
                                                {infos.recruit_type == 1 && '（全职）'}
                                                {infos.recruit_type == 2 && '（实习）'}
                                                {infos.recruit_type == 3 && '（全职/实习）'}
                                            </span>
                                        </div>
                                            <div className="fold" >
                                                {this.state[`show${index}`] ?
                                                    <img alt='img1' className='arr_d' src={down} /> :
                                                    <img alt='img1' className='arr_r' src={right} />}
                                            </div>
                                        </>}

                                </div>

                                {this.state[`show${index}`] &&
                                    <div className="desc second">
                                        {this.state[`show${index}`] &&
                                            <div className="detail0 second">{infos.introduction}</div>
                                        }
                                        <div className="detail">
                                            <div className="titles fir">工作内容</div>
                                            <ul className='con'>
                                                {infos.content && JSON.parse(infos.content).map((info, index) => {
                                                    return (
                                                        <li className='textss' key={index}>{info}  </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="detail">
                                            <div className="titles">工作要求</div>
                                            <ul className='con'>
                                                {infos.require && JSON.parse(infos.require).map((info, index) => {
                                                    return (
                                                        <li className='textss' key={index}>{info}  </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>

                                        {/* <div className="detail">
                                            <div className="titles">招聘流程</div>
                                            <ul className='con'>
                                                {infos.process && JSON.parse(infos.process).map((info, index) => {
                                                    return (
                                                        <li className='textss' key={index}>{info} </li>
                                                    )
                                                })}
                                            </ul>
                                        </div> */}


                                        {this.state[`show${index}`] && !mobile && <Button
                                            className='second'
                                            type="primary"
                                            style={{ marginBottom: 14, background: "#0059A5", borderRadius: 10 }}
                                            onClick={this.clickShowResume.bind(this)}>
                                            投递该岗位
                                        </Button>
                                        }

                                    </div>
                                }
                                {/* {this.state[`show${index}`] && !mobile && <Button
                                    className='second'
                                    type="primary"
                                    style={{ marginBottom: 14 }}
                                    onClick={this.clickShowResume.bind(this)}>
                                    <PlusOutlined />投递简历
                                </Button>
                                } */}


                            </div>
                        )
                    })}

                </div>}


            </div>
        );
    }
}

export default List;