import React, { Component } from 'react'
import Footer from '../component/Footer/footer'
import YouWillGet from './components/recruit/you_will_get'
import FAQList from './components/recruit/faq'
import RecruitTitle from './components/recruit/recruit_title'
import DeliveryMethod from './components/recruit/delivery_method'
import ContactWay from './components/recruit/contact_way'

import List from '../component/List/list'
import img162 from '../assets/img/162.jpg'
import img180 from '../assets/img/180.jpg'
// import recruit from '../assets/img/recruit.jpg'
import recruit1 from '../assets/img/recruit1.jpg'
import recruit2 from '../assets/img/recruit1.jpg'
import uploadImg from '../assets/img/upload.png'
import './recruit.scss'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'
import { Form, Button, Input, message, Modal, Upload, Select, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
const { Option } = Select;


class Recruit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recruitList: [],
            queestionList: [
                {
                    name: '杭州有哪些人才引进的政策？',
                    detail1: [
                        { 'title': '杭州人才引进政策种类丰富、补贴迅速、办理简便。针对2021年10月14日之后毕业的国内外本硕博毕业生按照社保缴纳情况分别发放1万、3万、10万的生活补贴；针对2020年2月24日后毕业的本科及以上学历应届生每户每年发放1万元租房补贴，最长可发放6年；针对留学回杭的人员可购买一辆免税国产轿车以及免费申领一个车牌；针对被杭州市认定为高层次的人才还可享受申领高额的租房补贴、购房补贴，拥有住房优先摇号权、免费畅游杭州范围内数十家景点、专享杭州图书馆专题查询服务等诸多政策福利。' }
                    ]
                },
                {
                    name: '希格斯投资的上下班时间和休假制度如何？',
                    detail1: [
                        { 'title': '希格斯投资的上下班和休假制度如何：希格斯投资执行一定程度的弹性上班制度。早晨8:30至9:10之间上班，中午11:30开始休息。下午13:00上班，17:00至17：40下班（取决于早晨上班的时间）。每周双休，执行国家法定休假制度和年休假制度。' },
                    ]
                },
                {
                    name: '在希格斯投资工作是否经常加班？',
                    detail1: [
                        { 'title': '希格斯投资倡导专注工作，而不是长时间工作。希格斯投资没有加班文化，包括程序员在内的员工都不需要经常性加班。下班之后仍然留在办公室继续工作，或者回家之后继续工作的现象是存在的，但和强制无关，而是出于每个人自身的控制和兴趣。' }
                    ]
                },
                {
                    name: '普通员工在希格斯投资的发展前景如何？',
                    detail1: [
                        { 'title': '希格斯投资身处一个快速增长的行业，而希格斯投资又是这个行业里面的一家很优秀的公司，这种快速发展给员工提供了很多的自我发展的机会。希格斯投资不断学习和不断超越的氛围也让员工在能力上能够获得持续成长。' },
                        { 'title': '希格斯投资的合伙人选拔是开放的和制度性的，任何员工都有可能通过自身的努力成为公司合伙人。' }
                    ]
                },
                {
                    name: '是否可以介绍一下希格斯投资的发展历史？',
                    detail1: [
                        { 'title': '有关创办希格斯投资之前团队的起源，和创办早期的一些小故事，可以参见微信公众号“交易门”的一篇访谈：' },
                        { 'titles': '“黑马”谈效俊：胜利最终属于解放军' },
                        { 'title': '这篇文章总编辑是李春晓，她是一位专业记录交易者故事的媒体工作者。李春晓记录的特点是忠实的再现受访者自己展现的信息，不会为了塑造人物或者故事需要而扭曲事实。在李春晓的坚持之下，“交易门”这个公众号也从来没有发布过有关公司或者个人的付费软文。这里非常感谢李春晓把希格斯投资初创时期的故事呈现给大家。' },
                    ]
                },
                {
                    name: '网上查到不止一家叫做“希格斯投资”的公司，这些公司和你们是否有关系？',
                    detail1: [
                        { 'title': '我们仅代表“杭州希格斯投资管理有限公司”，除此之外，其他的“希格斯投资”都与我们没有任何关系。' }
                    ]
                },
            ],
            showResumeModal: false,
            addData: {},
            mobile: false,
            fileList: [],
            positionList: []
        }
    }
    componentDidMount() {
        store.dispatch(changeIndex(this.props.location.pathname))
        if (document.body.clientWidth < 1000) {
            this.setState({
                mobile: true
            })
        } else {
            this.setState({
                mobile: false
            })
        }
        axios.get('https://www.higgsasset.com/higgs/web/api/getPositionWeb').
            then(res => {
                let data = res.data.data.list
                this.setState({
                    recruitList: data
                })
            })
        axios.get('https://www.higgsasset.com/higgs/web/api/getPositionListWeb').
            then(res => {
                let data = res.data.data
                this.setState({
                    positionList: data
                })
            })

    }

    componentWillUnmount() {
        window.scrollTo(0, 0)
    }

    showResume = () => {
        this.setState({
            showResumeModal: !this.state.showResumeModal
        })
    }

    onCancel = () => {
        this.setState({
            showResumeModal: false,
            addData: {}
        })
    }

    handleInput(e, name) {
        let obj = this.state.addData
        obj[name] = e.currentTarget.value
        this.setState({
            addData: obj
        })
    }

    handleChange = info => {
        this.setState({
            fileList: info.fileList
        })
        if (info.file.status == 'done') {
            let filename = info.file.response.data.filename
            let obj = this.state.addData
            obj['resume'] = filename
            this.setState({
                addData: obj
            })
        }
    }

    onOk = () => {
        let data = this.state.addData
        if (data.type == 2) {
            if (!data.internship) {
                message.error('请输入可实习时间!')
                return
            }
        }
        if (!data.resume) {
            message.error('请先上传简历!')
            this.setState({
                fileList: []
            })
            return
        }
        axios.post('https://www.higgsasset.com/higgs/web/api/onlineResumeSubmit', {
            addData: this.state.addData
        }).then(res => {
            if (res.data.code == 0) {
                message.success('简历投递成功!')
                this.setState({
                    showResumeModal: false,
                    addData: {},
                    fileList: []
                })
            }
        })
    }

    handleSelect(e, name) {
        let obj = this.state.addData
        obj[name] = e
        this.setState({
            addData: obj
        })
    }

    onChangeRadio = (e, name) => {
        let obj = this.state.addData
        obj[name] = e.target.value
        this.setState({
            addData: obj
        })
    }

    beforeUpload = file => {
        let data = this.state.addData
        let flag = true
        if (!data.position) {
            flag = false
            message.error('请输入应聘岗位!')
            this.setState({
                fileList: []
            })
            return Upload.LIST_IGNORE
        }
        if (!data.type) {
            flag = false
            message.error('请输入岗位性质!')
            this.setState({
                fileList: []
            })
            return Upload.LIST_IGNORE
        }
        if (!data.name) {
            flag = false
            message.error('请输入姓名!')
            this.setState({
                fileList: []
            })
            return Upload.LIST_IGNORE
        }
        if (!data.school) {
            flag = false
            message.error('请输入毕业院校!')
            this.setState({
                fileList: []
            })
            return Upload.LIST_IGNORE
        }
        if (!data.email) {
            flag = false
            message.error('请输入邮箱!')
            this.setState({
                fileList: []
            })
            return Upload.LIST_IGNORE
        }
        if (!data.channel) {
            flag = false
            message.error('请输入信息获取途径!')
            this.setState({
                fileList: []
            })
            return Upload.LIST_IGNORE
        }
        if (flag) {
            return true
        } else {
            return Upload.LIST_IGNORE
        }
    }

    clickJoinUs = () => {
        this.setState({
            showResumeModal: true
        })
    }

    render() {
        const { recruitList, queestionList, showResumeModal, fileList, addData, mobile, positionList } = this.state
        const props = {
            action: 'https://www.higgsasset.com/higgs/web/api/uploadOnlineResume',
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange,
            multiple: true,
        }

        return (
            <div>
                <img alt='img1' className='recruitImg' src={mobile ? recruit2 : recruit1} />
                <div className='recruit_content'>
                    <div className='poem'>
                        <div className='top'>
                            <div className="left">
                                <div className='text' >“世之奇伟、瑰怪，非常之观，常在于险远，而人之所罕至焉，故非有志者不能至也。” </div>
                                <div className='text line' >—— 王安石 《游褒禅山记》 </div>
                            </div>
                            <div className="right">
                                <img alt='img1' className='img' src={img162} />
                            </div>
                        </div>
                        <div className='desc'>希格斯投资有长久的和员工建立长期合作关系的传统。当我们选择你加入团队时，我们选择的不止是你的技能和知识，而是你作为一个有个人理想和抱负，情感和动机的完全的人。在团队持续学习和持续超越的文化之下，个人技能上暂时的短板不足为虑，而你身上所体现出的正直而高尚的人格，对未知事物热切的好奇心，和专注于工作而心无旁骛的探索精神，这些会给我们带来持久的价值。你会不断的加强我们的文化，给团队增加新的活力，而团队已有的文化也会让你感到受益匪浅，二者交相辉映，相得益彰。</div>
                    </div>
                    <RecruitTitle title="岗位招聘" type={1} />
                    <div className="recruit">
                        {/* <div className="leftTitle">岗位招聘</div> */}
                        <div className="wraps">
                            {recruitList.map((item, index) => {
                                return (
                                    <List type={1} item={item} key={index} recruitIndex={index + 2} onShowResume={this.showResume} />
                                )
                            })}
                        </div>
                    </div>
                    <RecruitTitle title="你将得到" type={1} />
                    <YouWillGet mobile={mobile} />
                    <RecruitTitle title="投递方式" type={1} />
                    <DeliveryMethod mobile={mobile} />
                    <RecruitTitle title="联系方式" type={1} />
                    <ContactWay mobile={mobile} />
                </div>

                <img alt='img1' className='recruitImg' src={img180} style={{ marginBottom: 60 }} />
                <RecruitTitle title="FAQ" type={2} />
                <div className='FAQ container'>
                    <div className="wraps">
                        {queestionList.map((item, index) => {
                            return (
                                <FAQList
                                    type={2} item={item} key={index}
                                />
                            )
                        })}
                    </div>
                </div>

                {showResumeModal &&
                    <Modal
                        title="投递简历"
                        centered
                        okText='确认'
                        cancelText='取消'
                        visible={showResumeModal}
                        onOk={this.onOk.bind(this)}
                        onCancel={this.onCancel.bind(this)}
                        width={500}>
                        <Form layout="vertical" hideRequiredMark>
                            <Form.Item name="position" label="应聘岗位（可多选）" rules={[{ required: true, message: '应聘岗位' }]}  >
                                <Select mode="multiple" placeholder="请选择应聘岗位" onChange={(e) => this.handleSelect(e, 'position')}>
                                    {positionList.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.position}>{item.position}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="type" label="岗位性质" rules={[{ required: true, message: '请输入岗位性质' }]}  >
                                <Radio.Group onChange={(e) => this.onChangeRadio(e, 'type')} >
                                    <Radio value={1}>全职</Radio>
                                    <Radio value={2}>实习</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {addData.type == 2 &&
                                <Form.Item name="internship"
                                    // label="可实习时间" 
                                    label={[
                                        <div key={1}><div style={{ color: 'red' }}> * </div></div>,
                                        <div key={2}> 可实习时间</div>,
                                    ]}
                                    rules={[{ required: true, message: '请输入可实习时间' }]}  >
                                    <Input placeholder="示例 2024.1-2024.5。暂不确定请说明。" onChange={(e) => this.handleInput(e, 'internship')} />
                                </Form.Item>}

                            <Form.Item name="name" label="姓名" rules={[{ required: true, message: '请输入姓名' }]}  >
                                <Input placeholder="请输入姓名" onChange={(e) => this.handleInput(e, 'name')} />
                            </Form.Item>
                            <Form.Item name="school" label="毕业院校" rules={[{ required: true, message: '毕业院校' }]}  >
                                <Input placeholder="请输入毕业院校" onChange={(e) => this.handleInput(e, 'school')} />
                            </Form.Item>
                            <Form.Item name="email" label="邮箱(该邮箱可用于投递进度查询)" rules={[{ required: true, message: '请输入邮箱' }]}  >
                                <Input placeholder="请输入邮箱" onChange={(e) => this.handleInput(e, 'email')} />
                            </Form.Item>
                            <Form.Item name="channel" label="信息获取途径" rules={[{ required: true, message: '请输入信息获取途径' }]}  >
                                <Input placeholder="请输入信息获取途径" onChange={(e) => this.handleInput(e, 'channel')} />
                            </Form.Item>
                            <Form.Item name="resume" label="简历" rules={[{ required: true, message: '请输入简历' }]} >
                                <Upload
                                    name="avatar"
                                    {...props}
                                    fileList={fileList}
                                    data={addData}
                                >
                                    <Button icon={<PlusOutlined />}>上传简历</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                }
                {mobile && <div className='resumeUpload' onClick={this.clickJoinUs.bind(this)}>
                    <img alt='uploadImg' className='img' src={uploadImg} />
                    <div className='title'>投递简历</div>
                </div>}
                <Footer></Footer>
            </div>
        );
    }
}

export default Recruit;