import React, { Component } from 'react'
import './footer.scss'
import { Link } from 'react-router-dom'
import beian from '../../assets/img/beian.png'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    // https://beian.miit.gov.cn/#/Integrated/index
    render() {
        const { type } = this.props
        return (
            <>
                {type === 2 ?
                    <div className='footer fixed'> HIGGS ASSET&ensp;&ensp;
                        <a className='icp' href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer"> 浙ICP备2020031631号</a> &ensp;&ensp;
                        <a className='icp' href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802011744" target="_blank"><img alt='beian' src={beian} />浙公网安备 33010802011744号</a>
                        <Link to='/contact/' className='links' >联系我们</Link>
                    </div> :
                    <div className='footer'> HIGGS ASSET&ensp;&ensp;
                        <a className='icp' href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer"> 浙ICP备2020031631号</a> &ensp;&ensp;
                        <a className='icp' href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802011744" target="_blank"><img alt='beian' src={beian} />浙公网安备 33010802011744号</a>
                        <Link to='/contact/' className='links' >联系我们</Link>
                    </div>}
            </>
        );
    }
}

export default Footer;