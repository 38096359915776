import React, { Component } from 'react';
import Footer from '../component/Footer/footer'
import './mission.scss'
import store from '../store/index';
import { changeIndex } from '../store/actionCreator'
class Mission extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        store.dispatch(changeIndex(this.props.location.pathname))
    }
    componentWillUnmount() {
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div>
                <img alt='img1' className='mission_Img' src={'https://www.higgsasset.com/img/web/43.jpg'} />
                <div className="mission_content bot">
                    <div className="left">希格斯使命</div>
                    <div className="right">
                        <p>希格斯投资的工作使命是什么？对于这个问题，我们会不带一点骄傲，也不带一点犹豫的回答：“实现市场的半强式有效，从而构建一个更好的社会。”</p>
                        <p>金融市场是整个市场经济体系的重要定价中枢，市场上交易出来的价格预示了各行各业的投资价值，并进而引导了整个社会的资源配置方向。 有人把金融市场对社会的影响类比于人脑的多巴胺系统，多巴胺系统调控人脑趋利避害，金融市场的价格也指挥着整个经济系统对未来的预期做出各种反应。 正如我们需要一个健康的多巴胺系统来拥有一个丰富多彩的人生，经济系统也需要一个理性的价格信号来维持良好运行。</p>
                        <p>对于整个国民经济，良好运作的“多巴胺系统”是什么样的呢？对于这一点，学术界早就（1965年）发明了一个名词来描述，那就是“有效市场”。 简而言之，有效市场的含义是指市场价格已经无偏差的反映了所有信息，市场价格是当前对价值的准确估计。 因此，在有效市场的条件下，人们可以信赖市场价格，根据其反映的信息来配置社会资源。</p>
                        <p>更进一步，学界又把有效市场区分为三种，分别是弱式有效，半强式有效，和强式有效。弱式有效市场指市场价格正确反映了所有的交易信息， 例如，价格、成交量、报单量等等，人们无法通过分析历史的交易数据来发现市场的定价偏差。 用更通俗的话来讲，在弱式有效条件下，技术分析是不会取得超额收益的。半强式有效指的是市场价格已经正确反映了所有已公开信息，其中既包括交易产生的信息，也包括各种宏观和微观的基本面信息， 在半强式有效的条件下，包括基本面分析在内的各种分析手段都无法取得超额收益。 强式有效指的是市场价格对价值的估计是如此之好，以至于即使是拥有内部信息的交易者，都无法取得超额收益。 </p>
                        <p>现在的市场价格是可以信赖的吗？根据我们的工作经验，我们可以相当有把握的认为，起码对于中国市场（我们的实际工作经验主要集中于中国市场）， 我们并不能总是完全信赖市场价格准确反映了其真实价值。在很多时候，我们的市场并非是弱式有效的，当然更没有达到半强式有效，甚至强式有效。 </p>
                    </div>
                </div>
                <div className="mission_content top  ">
                    <div className="left"></div>
                    <div className="right">
                        <p>如果要市场达到有效，至少的条件是市场中必须存在一部分理性交易者，他们有能力广泛获取各种公开信息， 并且正确研究和利用这些信息，以让他们能够在市场定价出现偏差时进行交易，从而使得价格回到正确反映历史信息的状态上来。</p>
                        <p>然而，这种能力是一种稀缺资源。看大风起时，百业扶摇而直上，又叹鼓声息时，经济凋敝如流水。 天下熙熙，岂料高位而接盘；天下攘攘，难测低点而割肉。如果市场充斥了太多缺乏研究能力的非理性投机者时，价格的剧烈波动和大幅偏离真实价值往往在所难免。总而言之，当市场的噪音过多时，国民经济的“多巴胺系统”就失效了。 </p>
                        <p>为什么我们认为我们能为市场提供这样一种能力呢？我们又为何敢于将实现市场的半强式有效作为自己的奋斗目标呢？ 这是因为以我们的经验来看，科学技术发展到今天，整个人类已经比历史上任何时候更加接近于了解市场运行背后的真实规律。 这样的进展得益于很多个学科的研究进展。统计学习理论拓展了人类以有限经验逼近高维度函数的方式； 心理学和神经科学使得人们对人类行为的动机，和背后的物理化学机制有了更深入的了解； 演化博弈论和社会物理学等学科则使得我们可以以更加模型化的方式来看待人类行为和社会现象。 这些学科并不是直接服务于金融市场价格研究的，但它们的发展给弄清楚金融市场的运行规律提供了宝贵支撑，给最终调整好国民经济的“多巴胺系统”提供了希望。 </p>
                        <p>两千多年前，我国的史学家司马迁曾经在《史记·太史公自序》中提到，他的父亲训导他， 周公去世五百年之后而有孔子，而今孔子去世已经有五百年了，又有谁能继承孔子的事业呢？ 司马迁牢记父亲的教诲，立下了“小子何敢让焉”（我怎么敢推辞呢？）这样谦逊而又坚定的誓言。 到了今天，这样的教导也激励着我们，我们虽然德薄才疏，但大任在前，我们同样 “小子何敢让焉”？ 司马迁在另一篇《报任安书》中写道，他网罗天下旧闻，考证其真实性，“亦欲以究天人之际，通古今之变，成一家之言”， 最终他不负所誓，著成信史。也希望我们能够以我们的工作，仰不愧于先贤，俯不怍于当世，为建成半强式有效市场和构建一个更好的社会贡献应有的一份力量。</p>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Mission;